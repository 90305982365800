import axios from 'axios'
import { BaseUrl  } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var Service = {

  getList: ( _token ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'card-registrations/?limit=1000', { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error, } )
      } );
    } )

  },

  getListOption: ( _token , _option ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'card-registrations/?limit='+_option.limit+'&offset='+_option.offset+_option.filter_string, { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error } )
      } );
    } )

  },
AddCustomerProfile:(_token,data)=>{
   //delete data.id
  // console.log("AddBus => ",data)
  const headers = {
      'Content-Type':'application/json',
      'Authorization':('Token '+_token),
      'apikey':Key
  }

  return new Promise((resolve, reject)=> {
      axios.post((BaseUrl +apiVersion)+'card-registrations/',data,{headers:headers})
      .then((response)=> {
          resolve({status:true,data:response})
      })
      .catch((error)=> {
          resolve({status:false,error:error})
      });
  })

}
};

export default Service;