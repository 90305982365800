import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, } from 'antd'
import { Spin } from 'antd'
import DataGrid from '../../components/dataGrid'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import service from '../../services/transactionCoin'
import filterFactory, { textFilter, dateFilter, selectFilter} from 'react-bootstrap-table2-filter';
import servicebus from '../../services/bus'
import FilterBus from '../../components/filterBus'


function dateFormatter(cell, row) {

  return (
    <span> {  new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB') } </span>
  );
}

const selectOptions = {
  'coins': 'coins',
  'bill': 'bill',
};


const columns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    footer: '',
    hidden: true,
  }, {
    dataField: 'transaction_datetime',
    text: 'วันที่เกิดธุรกรรม',
    sort: true,
    filter: dateFilter(),
    formatter: dateFormatter,
    footer: '',
  }, {
    dataField: 'transaction_carno',
    text: 'เลขคันรถ',
    sort: true,
    filter: textFilter(),
    footer: '',
  },{
    dataField: 'transaction_usertype',
    text: 'ชนิดธุรกรรม',
    sort: true,
    filter: selectFilter({
      options: selectOptions,
      style: {width: 130}
    }),
    footer: '',
  },{
    dataField: 'transaction_distance',
    text: 'สาย/ระยะ',
    filter: textFilter(),
    footer: '',
  },{
    dataField: 'driver_name',
    text: 'ชื่อ พขร.',
    footer: '',
  }, {
    dataField: 'transaction_price',
    text: 'ยอดธุรกรรม',
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
  }, {
    dataField: 'transaction_uid',
    text: 'เลขบัตร',
    footer: '',
  }
];


class Coin extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      objectlist: [],
      limit: 20,
      offset:0,
      filter_string: "",
      totalSize:0,
      sizePerPage:20,
      page:1,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Delete: "Delete",
        Show: "Show",
      },
      loading: false,
      busnumber: [],
      car_no: "",
    }
  }

  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      this.props.history.push( '/login' )
    }else{
      setTimeout(()=>{
        if(this.props.auth.token !== null){
            this.init_data(this.props.auth.token)
            this.bus_data(this.props.auth.token)
        }
      },800)
    }
  }

  spinLoading = (_bool) => {
    this.setState({loading:_bool})
  }

  formatData = ( _dataObj, _row ) => {
    return new Promise( ( resolve, reject ) => {
      let results = {}
      Object.keys( _dataObj ).forEach( ( row, index ) => {

        results[ row ] = _row[ row ]

        if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
          resolve( results )
        }

      } );
    } )
  }

  init_data = (_token) => {
    this.spinLoading(true)

    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option(this.props.auth.token,obj, this.state.car_no).then((res,err) => {

        if(res.status === 200){
          this.setState({
            objectlist: res.data.results,
            totalSize: res.data.count
          })
          this.spinLoading(false)
        }else{
          console.log("get data error !!")
        }
    })
  }

  init_data_option = (_token,_option, _carno) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option, _carno).then((res,err )=> {
        if (res.status){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }
      })
    })
  }

  bus_data = (_token) => {
    this.get_bus_data(_token).then((res,err) => {
      if(res.status === 200){
        console.log(res.data.results)
        this.setState({ busnumber: res.data.results})
      }else{
        console.log("get data error !!")
      }
    })
  }

  get_bus_data = (_token) => {
    return new Promise( ( resolve, reject ) => {
      servicebus.getList(_token).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }


  DataGridCallback  = ( _row, _index ) => {}


  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
    this.spinLoading(true)

    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' || type === 'sort' ){
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }

  changeCallback = (_dataline) => {
    this.setState({car_no: _dataline})
    this.init_data(this.props.auth.token)
  } 


  render() {

    let { objectlist , totalSize , page , limit } = this.state
console.log(objectlist)
    return (
    <Spin spinning={this.state.loading} delay={300} tip="Loading..."> <div>
      <Row gutter={16}>
        <Col span={24}>
          <h2>ข้อมูลธุรกรรมเงินสด(ออนไลน์)</h2>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
              <Col span={24}>
               
              </Col>
      </Row>
      <br/>
      <Row gutter={16}>
        <Col span={24}>
          <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
        </Col>
      </Row>
    </div>
    </Spin>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth}
}

export default connect( mapStateToProps )( Coin );
