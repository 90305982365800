import { all, call, put, takeLatest, select } from "redux-saga/effects"
import serviceBus from "../../../services/bus"
import Actions, { ActionType } from "./busTableRedux"

export function* fetchBusData(action) {

    const tag = "[FETCH_BUS_DATA]";
    try {
        // console.log(tag)
        const response = yield call(
            serviceBus.getListBus,
            action.token
        );
        
        if(response.status){
            yield put(Actions.fetchBusSuccess(response.data.data))
        }else{
            console.log(`${tag} response error`) 
            yield put(Actions.fetchBusFailure(response.error))
        }
        
    } catch (e) {
        console.log(`${tag} error`) 
    }
}

export function* fetchBusAgain(action) {
    console.log("SAGA fetchBusAgain on ")
    const tag = "[FETCH_BUS_AGAIN]";
    try {

        console.log("action => ",action)
        yield put(Actions.fetchBusAgain(action.fetchBusAgain))
       
    } catch (e) {
        console.log(`${tag} error`) 
    }
}

export default function* rootSaga() {
    yield all([
      takeLatest(ActionType.FETCH_BUS_DATA,fetchBusData),
      takeLatest(ActionType.FETCH_BUS_AGAIN,fetchBusAgain)
    ]);
}