import React, { Component } from "react"
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table'
import {Icon } from 'antd'

  const selectRowProp = {
    mode: 'checkbox'
  };
  
  const options = {
    page: 1,  // which page you want to show as default
    sizePerPageList: [
        {
          text: '20',
          value: 20,
        }, {
          text: '50',
          value: 50,
        }, {
          text: '100',
          value: 100,
        }, {
          text: '1000',
          value: 1000,
        },{
          text: '10000',
          value: 10000,
        },
      ],
    sizePerPage: 20,
    pageStartIndex: 1,
    prePage: 'ก่อนหน้า', // Previous page button text
    nextPage: 'ถัดไป', // Next page button text
    firstPage: 'หน้าแรก', // First page button text
    lastPage: 'หน้าสุดท้าย', // Last page button text
    paginationShowsTotal: renderShowsTotal,
    exportCSVBtn: createCustomExportCSVButton
    
  };

  function  renderShowsTotal(start, to, total) {
    return (
        <span style={{ marginLeft: 10 }} >
          Showing {start} to {to} of {total} Results
        </span>
    );
  }

  function createCustomExportCSVButton()  {
    return (
      <ExportCSVButton style={{color:'white',marginBottom: 10}} className="btn btn-warning btn-sm">
        <Icon type="file-excel" style={{ fontSize: '16px', color: '#fff'}}/>ส่งออกเป็น CSV</ExportCSVButton>
    );
  }

  class DataGridwithHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
          page: 1,
          data: props.dataList,
          column: props.col
        }
      }

  render() {
    return (
      <div>
        <div style={{float:'right',marginBottom: 10}}> พบ {this.props.totalSize} รายการ </div>
      <BootstrapTable footer footerData={this.props.footerData} exportCSV={ true } data={ this.props.dataList } selectRow={ selectRowProp } pagination={ true } options={ options} keyField='id'>
        {
    this.props.col.map( column => {
        return (
            <TableHeaderColumn dataField={ column.dataField } hidden={column.hidden} 
                              row={column.row} colSpan={column.colspan}
                              rowSpan={column.rowspan} width={column.width}
                              dataSort={column.sort} dataFormat={column.formatter}>
            { column.text }
            </TableHeaderColumn>
        );
    })
    }
      </BootstrapTable>
      </div>
    );
}
  }

  export default DataGridwithHeader;