import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import DataGrid from '../../components/dataGrid'
import { isEmpty } from '../../lib/utils'
import service from '../../services/card_customers'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, selectFilter} from 'react-bootstrap-table2-filter';

function dateFormatter(cell, row) {

    return (
      <span> {  new Date(cell).toLocaleDateString('en-GB') } </span>
    );
}

function phoneFormatter(cell, row) {

  if (cell == null){
    return (
      <span> - </span>
    );
  }
  return (
    <span> {  cell  } </span>
  );
}


const columns = [
    {
        dataField: 'id',
        text: 'ID',
        sort: true,
        hidden: true,
    },
    {
        dataField: 'name',
        text: 'ชื่อ',
        sort: true,
        filter: textFilter(),
    },
    {
        dataField: 'phone',
        text: 'เบอร์โทรศัพท์',
        sort: true,
        formatter: phoneFormatter,
    },
    {
        dataField: 'email',
        text: 'E-mail',
        sort: true,
    },
    {
        dataField: 'created_at',
        text: 'วันที่ลงทะเบียน',
        sort: true,
        formatter: dateFormatter,
    },
    {
        dataField: 'serial_number',
        text: 'Serial Number',
        sort: true,
        filter: textFilter(),
    },
]

class Card_customers extends Component {

    constructor( props ) {
      super( props )
      this.state = {
        objectlist:[],
          limit: 20,
          offset:0,
          filter_string: "",
          totalSize:0,
          sizePerPage:20,
          page:1,
          loading: true,
      }
    }
  
    componentDidMount() {
      if ( isEmpty( getItem( Session.header ) ) ) {
        this.props.history.push( '/login' )
      } else {
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
          }
        },800)
  
      }
  
    }
  
    init_data = (_token) => {
  
          let obj = {
            limit: this.state.limit,
            offset: this.state.offset,
            filter_string: this.state.filter_string
          }
          this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err) => {
  
              if(res.state){
                this.setState({
                  objectlist: res.data.data.results,
                  totalSize:res.data.data.count
                })
                this.spinLoading(false)
              }else{
                console.log("get data error !!")
              }
  
          })
  
    }
  
  
    init_data_option = (_token,_option) => {
      return new Promise( ( resolve, reject ) => {
        service.getListOption(_token,_option,this.state.reportType).then((res,err)=> {
  
          if (res.status){
            if(res.data.status === 200){
              resolve({state:true,data:res.data})
            }
          }else{
            if(res.error.response.status === 401){
                this.props.history.push( '/login' )
            }
          }
  
        })
      })
    }
  
  
    DataGridCallback = ( _row, _index ) => {}
  
    PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
  
      let obj = {
        limit: _limit,
        offset: _offset,
        filter_string: _filter_string,
        loading:false
      }
  
      if(type === 'filter' || type === 'pagination' || type === 'sort'  ){
        this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
            if(res.state){
              this.setState({
                limit: _limit,
                offset: _offset,
                page: _page,
                objectlist: res.data.data.results
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }
  
          })
      }
  
    }
  
  
    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }
  
    render() {
  
      const { objectlist , totalSize , page , limit , loading} = this.state
  
      return (
        <div>
          <Row gutter={16}>
            <Col span={24}>
              <h2>รายการบัตรลงทะเบียนจากแอปพลิเคชัน</h2>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}></Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              <Spin spinning={loading} delay={300} tip="Loading...">
                <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
              </Spin>
            </Col>
          </Row>
        </div>
      );
    }
  }
  
  const mapStateToProps = state => {
    return { auth: state.auth }
  }
  
  export default connect( mapStateToProps )( Card_customers );