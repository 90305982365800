import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import DataGrid from '../../components/dataGrid'
import { isEmpty } from '../../lib/utils'
import service from '../../services/reportBusSummary'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, selectFilter} from 'react-bootstrap-table2-filter';
import DateRange from '../../components/dateRange'

function priceFormatter(cell, row) {
	var formatter = new Intl.NumberFormat('th', {
		style: 'currency',
		currency: 'THB',
	});
	return (
		<span>{ formatter.format(cell) } </span>
	);
}

const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'busnumber',
      text: 'หมายเลขรถ',
      sort: true,
      footer: '',
    },
    {
      dataField: 'card',
      text: 'Card',
      sort: true,
      formatter: priceFormatter,
      footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
      formatter: priceFormatter,
      align: 'right'
    },
    {
      dataField: 'topup',
      text: 'เติมเงิน',
      sort: true,
      formatter: priceFormatter,
      footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
      formatter: priceFormatter,
      align: 'right'
    },
    {
      dataField: 'coins',
      text: 'เหรียญ (ออนไลน์)',
      sort: true,
      formatter: priceFormatter,
      footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
      formatter: priceFormatter,
      align: 'right'
    },
    {
      dataField: 'coins_count',
      text: 'เหรียญ (manual)',
      sort: true,
      formatter: priceFormatter,
      footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
      formatter: priceFormatter,
      align: 'right'
    },
    {
        dataField: 'bill',
        text: 'ธนบัตร (ออนไลน์)',
        sort: true,
        formatter: priceFormatter,
        footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
        formatter: priceFormatter,
        align: 'right'
    },
    {
        dataField: 'bill_count',
        text: 'ธนบัตร (manual)',
        sort: true,
        formatter: priceFormatter,
        footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
        formatter: priceFormatter,
        align: 'right'
    },
    {
        dataField: 'fare',
        text: 'ยอดนับเงิน (ออนไลน์)',
        sort: true,
        formatter: priceFormatter,
        footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
        formatter: priceFormatter,
        align: 'right'
    },
    {
        dataField: 'fare_count',
        text: 'ยอดนับเงิน (manual)',
        sort: true,
        formatter: priceFormatter,
        footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
        formatter: priceFormatter,
        align: 'right'
    },
    {
        dataField: 'fare_diff',
        text: 'ผลต่าง',
        sort: true,
        footer: '',
        align: 'right'
    },
    {
        dataField: 'fare_percent',
        text: 'ร้อยละผลต่าง',
        sort: true,
        footer: '',
        align: 'right'
    },
    {
        dataField: 'total',
        text: 'สรุปยอดเงิน',
        sort: true,
        formatter: priceFormatter,
        footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
        formatter: priceFormatter,
        align: 'right'
    },
];

class ReportBusSummary extends Component {

    constructor( props ) {
      super( props )
      this.state = {
        objectlist:[],
          limit: 20,
          offset:0,
          filter_string: "",
          totalSize:0,
          sizePerPage:10,
          page:1,
          loading: true,
          startdate: '',
          enddate: ''
      }
    }

    componentDidMount() {
      if ( isEmpty( getItem( Session.header ) ) ) {
        this.props.history.push( '/login' )
      } else {
        let tmp_date = new Date();
        let curr_date = tmp_date.getFullYear() + '-' + (tmp_date.getMonth()+1) + '-' + tmp_date.getDate()
        this.setState({
          startdate: curr_date,
          enddate: curr_date
        })
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
          }
        },800)
      }

    }

    init_data = (_token) => {

          let obj = {
            limit: this.state.limit,
            offset: this.state.offset,
            filter_string: this.state.filter_string,
            startdate: this.state.startdate,
            enddate: this.state.enddate
          }

          this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err) => {
              if(res.state){
                this.setState({
                  objectlist: res.data.data,
                  totalSize:res.data.data.length
                })
                this.spinLoading(false)
              }else{
                console.log("get data error !!")
              }

          })

    }


    init_data_option = (_token,_option) => {
      return new Promise( ( resolve, reject ) => {
        service.getListOption(_token,_option).then((res,err)=> {
          if (res.status){
            if(res.data.status === 200){
              resolve({state:true,data:res.data})
            }
          }else{
            if(res.error.response.status === 401){
                this.props.history.push( '/login' )
            }
          }

        })
      })
    }


    DataGridCallback = ( _row, _index ) => {}

    PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

      let obj = {
        limit: _limit,
        offset: _offset,
        filter_string: _filter_string,
        loading:false,
        startdate: this.state.startdate,
        enddate: this.state.enddate
      }

      if(type === 'filter' || type === 'pagination' || type === 'sort'  ){
        this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
            if(res.state){
              this.setState({
                limit: _limit,
                offset: _offset,
                page: _page,
                objectlist: res.data.data
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }

          })
      }

    }

    dataRangeCallback = ( _dateCallback ) => {
      this.setState({

        startdate: _dateCallback.start_date,
        enddate: _dateCallback.end_date
      });
      this.init_data(this.props.auth.token);
    }


    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }

    render() {

      const { objectlist , totalSize , page , limit , loading} = this.state

      return (
        <div>
          <Row gutter={16}>
            <Col span={24}>
              <h2>รายงานยอดค่าโดยสารแบบแยกประเภท</h2>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              <DateRange callback={this.dataRangeCallback} />
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              <Spin spinning={loading} delay={300} tip="Loading...">
                <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
              </Spin>
            </Col>
          </Row>
        </div>
      );
    }
  }

  const mapStateToProps = state => {
    return { auth: state.auth }
  }

  export default connect( mapStateToProps )(ReportBusSummary);
