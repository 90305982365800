import axios from 'axios'
import { BaseUrl  } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var Service = {

  getList: ( _token ) => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key,
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'drivers/?limit=1000', { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error } )
      } );
    } )

  },

  getListOption: ( _token , _option ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'drivers/?limit='+_option.limit+'&offset='+_option.offset+_option.filter_string, { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error, } )
      } );
    } )

  },

  AllDrivers: ( _token ) => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key,
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'drivers/', { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error } )
      } );
    } )

  },

  AddDriver: ( _token , data ) => {
    delete data.id
    const employee_name = data.employee_name
    delete data.employee_name

    // console.log("service => AddDriver => ",data)
    const headers = {
        'Content-Type' : 'application/json',
        'Authorization' : ('Token '+_token),
        'apikey' : Key
    }

    return new Promise((resolve, reject)=> {
        axios.post((BaseUrl +apiVersion)+'drivers/',data,{headers:headers})
        .then((response)=> {
            resolve({status:true,data:response})
        })
        .catch((error)=> {
            resolve({status:false,error:error})
        });
    })

  },

  FullUpdateDriver:(_token,data)=>{
      const id = data.id
      delete data.id
      delete data.employee_name
      console.log("FullUpdateDriver => ",data)
      const headers = {
          'Content-Type':'application/json',
          'Authorization':('Token '+_token),
          'apikey':Key
      }

      return new Promise((resolve, reject)=> {
          axios.put((BaseUrl +apiVersion)+'drivers/'+id+'/',data,{headers:headers})
          .then((response)=> {
              resolve({status:true,data:response})
          })
          .catch((error)=> {
              resolve({status:false,error:error})
          });
      })

  },

  DeleteDriver:(_token,_id)=>{

      const headers = {
          'Content-Type':'application/json',
          'Authorization':('Token '+_token),
          'apikey':Key
      }

      return new Promise((resolve, reject)=> {
          axios.delete((BaseUrl +apiVersion)+'drivers/'+_id+'/',{headers:headers})
          .then((response)=> {
              resolve({status:true,data:response})
          })
          .catch((error)=> {
              resolve({status:false,error:error})
          });
      })

  }




};

export default Service;
