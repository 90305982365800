import React, { Component } from "react"
import { Button
        , Modal
        , Form
        , Input
        , DatePicker
        , AutoComplete
      , Row ,Col} from 'antd';
const { Option} = AutoComplete;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  
  class extends React.Component {
    
    render() {
      const { visible, onCancel, onCreate, form, dataSource, onChangeDate , onchangeInput, onchangeAuto, onchangeString} = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const options = dataSource.map(item => (
        <Option key={item.id}>
        {item.id}
        <span style={{paddingLeft:30}}>{item.bus_number}</span>
        </Option>
        ))
      return (
        <Modal
          width={860}
          visible={visible}
          footer={false}
          title="เพิ่มข้อมูลการเติมเชื้อเพลิง"
          onCancel={onCancel}
        >
          <div style={{marginTop:10,marginBottom:10}}>
          <Form {...formItemLayout}>
            <Form.Item label="bus id">
              {getFieldDecorator('bus_id',)(<AutoComplete
                                    style={{ width: 300 }}
                                    dataSource={options}
                                    placeholder="bus id"
                                    optionLabelProp = "value"
                                    onChange={(event)=>{onchangeAuto(event,"bus")}}
                                  />)}
            </Form.Item>
            <Form.Item label="วันที่เติม">
              {getFieldDecorator('fuel_date')(<DatePicker format="YYYY-MM-DD" style={{width:300}} onChange={(date,dateString)=>{onChangeDate(dateString,"fuel_date")}} />)}
            </Form.Item>
            <Form.Item label="เลขไมล์ก่อนหน้า">
              {getFieldDecorator('mileage_previous')( <Input style={{width:300}}  placeholder="เลขไมล์ก่อนหน้า" type="number" onChange={(event)=>{onchangeInput(event,"mileage_previous")}} />)}
            </Form.Item>
            <Form.Item label="เลขไมล์ปัจจุบัน">
              {getFieldDecorator('mileage_current')( <Input style={{width:300}}  placeholder="เลขไมล์ปัจจุบัน" type="number" onChange={(event)=>{onchangeInput(event,"mileage_current")}} />)}
            </Form.Item>
            <Form.Item label="ความต่างเลขไมล์">
              {getFieldDecorator('mileage')(<Input style={{width:300}}  placeholder="ความต่างเลขไมล์" type="number" onChange={(event)=>{onchangeInput(event,"mileage")}} />)}
            </Form.Item>
            <Form.Item label="ปริมาณแก๊สที่เติม">
              {getFieldDecorator('gas_volume')(<Input style={{width:300}}  placeholder="ปริมาณแก๊สที่เติม" type="number" onChange={(event)=>{onchangeInput(event,"gas_volume")}} />)}
            </Form.Item>
            <Form.Item label="ราคาแก๊สที่เติม">
              {getFieldDecorator('gas_money')(<Input style={{width:300}}  placeholder="ราคาแก๊สที่เติม" type="number" onChange={(event)=>{onchangeInput(event,"gas_money")}} />)}
            </Form.Item>
            <Form.Item label="อัตราการสิ้นเปลือง">
              {getFieldDecorator('gas_volume_vga')(<Input style={{width:300}}  placeholder="อัตราการสิ้นเปลือง" type="number" onChange={(event)=>{onchangeInput(event,"gas_volume_vga")}} />)}
            </Form.Item>
            <Form.Item label="ระยะทาง">
              {getFieldDecorator('gas_mileage_vga')(<Input style={{width:300}}  placeholder="ระยะทาง" type="number" onChange={(event)=>{onchangeInput(event,"gas_mileage_vga")}} />)}
            </Form.Item>
            <Form.Item label="ราคาเต็ม">
              {getFieldDecorator('gas_full_unit_price')(<Input style={{width:300}}  placeholder="ราคาเต็ม" type="number" onChange={(event)=>{onchangeInput(event,"gas_full_unit_price")}} />)}
            </Form.Item>
            <Form.Item label="ส่วนลด">
              {getFieldDecorator('gas_discount_unit_price')(<Input style={{width:300}}  placeholder="ส่วนลด" type="number" onChange={(event)=>{onchangeInput(event,"gas_discount_unit_price")}} />)}
            </Form.Item>
            <Form.Item label="ผลต่าง">
              {getFieldDecorator('gas_unit_price_difference')(<Input style={{width:300}}  placeholder="ผลต่าง" type="number" onChange={(event)=>{onchangeInput(event,"gas_unit_price_difference")}} />)}
            </Form.Item>
            <Form.Item label="รวมราคาเต็ม">
              {getFieldDecorator('gas_total_price')(<Input style={{width:300}}  placeholder="รวมราคาเต็ม" type="number" onChange={(event)=>{onchangeInput(event,"gas_total_price")}} />)}
            </Form.Item>
            <Form.Item label="รวมราคาจ่าย">
              {getFieldDecorator('gas_total_paid')(<Input style={{width:300}}  placeholder="รวมราคาจ่าย" type="number" onChange={(event)=>{onchangeInput(event,"gas_total_paid")}} />)}
            </Form.Item>
            <Form.Item label="รวมส่วนลด">
              {getFieldDecorator('gas_total_discount')(<Input style={{width:300}}  placeholder="รวมส่วนลด" type="number" onChange={(event)=>{onchangeInput(event,"gas_total_discount")}} />)}
            </Form.Item>
            <Form.Item label="สถานีบริการ">
              {getFieldDecorator('service_station')(<Input style={{width:300}}  placeholder="สถานีบริการ" onChange={(event)=>{onchangeString(event,"service_station")}} />)}
            </Form.Item>
          </Form>
            <Row gutter={32} style={{marginTop:15,marginBottom:15}}>
                      <Col style={{textAlign:'right'}} xs={{ span: 16, offset: 4 }} >
                          <div style={{marginRight:20}}>
                              <Button style={{width:100}} type="danger" onClick={onCancel} >ยกเลิก</Button> 
                              {'  '}
                              <Button style={{width:100}} onClick={onCreate} type="primary">บันทึก</Button>
                          </div>
                      </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  },
);

class ModalAdd extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        dataFromParent:props.dataFromParent,
        visible: false,
        dataInForm:{}
    }
}
 
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    setTimeout(()=>{
      this.props.callback(this.state.dataInForm)
    },1000)
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onChangeDate = (dateString,key) => {
    let dataObj = this.state.dataInForm
    dataObj[key] = dateString
    this.setState({dataInForm:dataObj})
  }

  onchangeInput = (_event,_key) => {
    let data = this.state.dataInForm
    data[_key] = (parseInt(_event.target.value))
    this.setState({dataInForm:data})
  }

  onchangeString = (_event,_key) => {
    let data = this.state.dataInForm
    data[_key] = (_event.target.value)
    this.setState({dataInForm:data})
  }

  onchangeAuto = (_event,_key) => {
    let data = this.state.dataInForm
    data[_key] = (_event)
    this.setState({dataInForm:data})
    console.log(this.state.dataInForm)
  }

  render() {
    return (
      <div>
        <Button type="primary" style={{float: "right"}} onClick={this.showModal}>
          เพิ่มรายการ
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          dataSource={this.props.dataFromParent}
          onChangeDate={this.onChangeDate}
          onchangeInput={this.onchangeInput}
          onchangeAuto={this.onchangeAuto}
          onchangeString={this.onchangeString}
        />
      </div>
    );
  }
}
export default ModalAdd;
