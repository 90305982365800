import { all, call, put, takeLatest, select } from "redux-saga/effects"
import Service from "../../../services/card"
import Actions, { ActionType } from "./cardTableRedux"

export function* fetchCardData( action ) {

  const tag = "[FETCH_CARD_DATA]";

  try {
    const response = yield call( Service.getList, action.token );

    if ( response.status ) {
      yield put( Actions.fetchCardSuccess( response.data.data ) )
    } else {
      console.log( `${ tag } response error` )
      yield put( Actions.fetchCardFailure( response.error ) )
    }

  } catch ( e ) {
    console.log( `${ tag } error` )
  }
}

export default function* rootSaga() {
  yield all( [takeLatest( ActionType.FETCH_CARD_DATA, fetchCardData )] );
}
