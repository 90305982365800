import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button, Icon } from 'antd'
import { isEmpty } from '../../lib/utils'
import service from '../../services/reportBusSummary'
import Session, { getItem } from '../../lib/session'
import ChartPie from '../../components/chartPie'

const COLORS = ['#fa990e', '#054982'];
class HeaderDashboard extends Component {

    constructor( props ) {
      super( props )
      this.state = {
        objectlist:[],
          limit: 20,
          offset:0,
          totalSize:0,
          sizePerPage:10,
          page:1,
          loading: true,
          totalTran: 0,
          startdate: '',
          enddate: '',
          objectlist2:[],
          totalSize2:[],
          chartData: [],
      }
    }

    componentDidMount() {
      if ( isEmpty( getItem( Session.header ) ) ) {
        this.props.history.push( '/login' )
      } else {
        let tmp1 = new Date();
        let tmp2 = new Date();
        tmp1.setDate(tmp1.getDate() - 15);
        let first_date = tmp1.getFullYear() + '-' + (tmp1.getMonth()+1) + '-' + (tmp1.getDate())
        let last_date = tmp2.getFullYear() + '-' + (tmp2.getMonth()+1) + '-' + (tmp2.getDate()-1)
//console.log(first_date,last_date)
        this.setState({
          startdate: first_date,
          enddate: last_date
        })
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
              this.init_data2(this.props.auth.token)
          }
        },800)
      }

    }

    init_data = (_token) => {

          let obj = {
            limit: this.state.limit,
            offset: this.state.offset,
            filter_string: this.state.filter_string,
          }

          this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err) => {
              if(res.state){
                this.setState({
                  objectlist: res.data.data,
                  totalSize:res.data.data.length
                })
                this.addItem();
                this.spinLoading(false)
              }else{
                console.log("get data error !!")
              }

          })

    }


    init_data_option = (_token,_option) => {
      return new Promise( ( resolve, reject ) => {
        service.getList(_token,_option).then((res,err)=> {
          if (res.status){
            if(res.data.status === 200){
              resolve({state:true,data:res.data})
            }
          }else{
            if(res.error.response.status === 401){
                this.props.history.push( '/login' )
            }
          }

        })
      })
    }

    init_data2 = (_token) => {

      let obj = {
        limit: this.state.limit,
        offset: this.state.offset,
        filter_string: this.state.filter_string,
        startdate: this.state.startdate,
        enddate: this.state.enddate
      }

      this.init_data_option2(this.props.auth.token,obj).then((res,err) => {
          if(res.state){
            this.setState({
              objectlist2: res.data.data,
              totalSize2:res.data.data.length
            })
            this.addItem2();
          }else{
            console.log("get data error !!")
          }

      })

}


init_data_option2 = (_token,_option) => {
  return new Promise( ( resolve, reject ) => {
    service.getListOption(_token,_option).then((res,err)=> {
      if (res.status){
        if(res.data.status === 200){
          console.log("a")
          resolve({state:true,data:res.data})
        }
      }

    })
  })
}


    addItem = () => {
        let ing = 0
        this.state.objectlist.map((item, index) => {
            ing += parseInt(item.total);
        })
        this.setState({totalTran : ing})
    }

    addItem2 = () => {
      let ing = 0;
      let ing2 = 0;
      this.state.objectlist2.map((item, index) => {
          ing += parseInt(item.card);
          ing2 += parseInt(item.fare);
      })
      let tdata = [{name : "บัตรโดยสาร", value: ing}, 
                   {name : "เงินสด", value: ing2}]
      console.log(tdata)
      this.setState({chartData: tdata})
  }


    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }

    render() {
      let tmp_date = new Date();
      let curr_date = tmp_date.getDate() + '/' +  (tmp_date.getMonth()+1) + '/' + tmp_date.getFullYear()
                      + ' ' + tmp_date.getHours() + ':' + tmp_date.getMinutes();
      
      const { chartData , totalSize , totalTran , loading} = this.state
      return (
        <div>
            <Row>
              <Col span={7}>
                  <div style={{backgroundColor:"#359d93", width: "90%", height:120, color:"white" }}>
                    <Row>
                      <Col span={8}>
                        <Icon type="dollar" style={{ fontSize: '52px', paddingTop:'40%', paddingLeft: 30 }} theme="outlined" />
                      </Col>
                      <Col span={16}>
                        <div style={{fontSize:18, paddingRight: 20, paddingTop:20 ,textAlign:"right"}}>
                          รายได้ประจำวันนี้
                        </div>
                        <div style={{fontSize:11, textAlign:"right", paddingRight: 20}}>({curr_date} น.)</div>
                        <div style={{fontSize:32, paddingRight: 20, paddingTop:5, textAlign:"right"}}>
                          ฿ {totalTran.toLocaleString()}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div style={{backgroundColor:"#823066", width: "90%", height:120, color:"white", marginTop:30 }}>
                <Row>
                  <Col span={8}>
                    <Icon type="car" style={{ fontSize: '52px', paddingTop:'40%', paddingLeft: 30 }} theme="outlined" />
                  </Col>
                  <Col span={16}>
                    <div style={{fontSize:18, paddingRight: 20, paddingTop:20 ,textAlign:"right"}}>
                      จำนวนรถที่ให้บริการ
                    </div>
                    <div style={{fontSize:11, textAlign:"right", paddingRight: 20}}>({curr_date} น.)</div>
                    <div style={{fontSize:32, paddingRight: 20, paddingTop:5, textAlign:"right"}}>
                     {totalSize}
                    </div>
                  </Col>
                </Row>
            </div>
              </Col>
              <Col span={8}>
              <div style={{backgroundColor:"#85a4c3", width: "90%", height:270, color:"white" }}>
                    <div style={{fontSize:18, paddingRight: 20, paddingTop:20 ,textAlign:"right"}}>
                      สัดส่วนค่าโดยสาร</div>
                    <div style={{fontSize:11, textAlign:"right", paddingRight: 20, paddingTop:0}}>ย้อนหลัง 15 วัน</div>
                    <div style={{marginLeft:40, marginTop: 0}}>
                    <ChartPie data={chartData} color={COLORS}/>
                    </div>
            </div>
              </Col>
             
            </Row>
        </div>
      );
    }
  }

  const mapStateToProps = state => {
    return { auth: state.auth }
  }

  export default connect( mapStateToProps )(HeaderDashboard);