const dataSchema = {
  required: [],
  dataStructure: {
    id: null,
    serial_number: null,
    tag: null,
    issued_date: null,
    valid_until: null,
    register_date: null,
    first_used_date: null,
    sequence_number: null,
    purse: null,
    bonus: null,
    deposit: null,
    card_type: null,
    card_type_name: null,
    personalization: null,
    concession: null,
    is_test_card: null,
    block_flag: null,
    status: null,
  },
  properties: {
    id: {
      key: 'id',
      type: 'integer',
      label: 'ID'
    },
    serial_number: {
      key: 'serial_number',
      type: 'string',
      label: 'Serial number',
      max_length: '100'
    },
    tag: {
      key: 'tag',
      type: 'string',
      label: 'Tag',
      max_length: '100'
    },
    issued_date: {
      key: 'issued_date',
      type: 'datetime',
      label: 'วันที่ผลิต'
    },
    valid_until: {
      key: 'valid_until',
      type: 'datetime',
      label: 'วันที่หมดอายุ'
    },
    register_date: {
      key: 'register_date',
      type: 'datetime',
      label: 'วันที่ลงทะเบียน'
    },
    first_used_date: {
      key: 'first_used_date',
      type: 'datetime',
      label: 'วันแรกที่ใช้'
    },
    sequence_number: {
      key: 'sequence_number',
      type: 'integer',
      label: 'ลำดับธุรกรรม'
    },
    purse: {
      key: 'purse',
      type: 'integer',
      label: 'ยอดธุรกรรม'
    },
    bonus: {
      key: 'bonus',
      type: 'integer',
      label: 'โบนัส'
    },
    deposit: {
      key: 'deposit',
      type: 'integer',
      label: 'ยอดเงินของบัตร'
    },
    card_type: {
      key: 'card_type',
      type: 'string',
      label: 'ประเภทของบัตร'
    },
    card_type_name: {
      key: 'card_type_name',
      type: 'string',
      label: 'ชื่อประเภทของบัตร'
    },
    personalization: {
      key: 'personalization',
      type: 'choice',
      label: 'Personalization'
    },
    concession: {
      key: 'concession',
      type: 'choice',
      label: 'Concession'
    },
    is_test_card: {
      key: 'is_test_card',
      type: 'boolean',
      label: 'Is test card'
    },
    block_flag: {
      key: 'block_flag',
      type: 'integer',
      label: 'Block flag'
    },
    status: {
      key: 'status',
      type: 'integer',
      label: 'สถานะ'
    },
  }
}

export const schema = dataSchema
