import { all } from "redux-saga/effects"
import authSaga from "./auth/authSaga"
import menuSaga from "./menu/menuSaga"
import busSaga from "./bus/busTable/busTableSaga"
import cardSaga from "./card/cardTable/cardTableSaga"
import driverSaga from "./driver/driverTable/driverTableSaga"
import bus_stopTableSaga from "./bus_stop/bus_stopTable/bus_stopTableSaga"
import lineTableSaga from "./line/linesTable/linesSaga"

export default function* rootSaga() {
  yield all([
    authSaga(),
    menuSaga(),
    busSaga(),
    cardSaga(),
    driverSaga(),
    bus_stopTableSaga(),
    lineTableSaga(),
  ]);
}
