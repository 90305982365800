import { createActions, createReducer, } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_BUSSTOPTABLE_REDUX]'

const functionDefinitions = {
  fetchLinesData: ['token'],
  fetchLinesSuccess: ["fetchLinesSuccess"],
  fetchLinesFailure: [ "fetchLinesFailure" ]
}

const { Types, Creators, } = createActions( functionDefinitions )

export const ActionType = Types;
export default Creators;

const initState = {
  isLoading: false,
  payLoad: null,
  error: null,
}

export const INITIAL_STATE = Immutable( initState )

export const fetchLinesData = ( state, action ) => {
  return state.merge( { isLoading: true, payLoad: action.token, error: null, } );
}

export const fetchLinesSuccess = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: action.fetchLinesSuccess, error: null, } );
}

export const fetchLinesFailure = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: null, error: action.fetchLinesFailure, } );
};

export const reducer = createReducer( INITIAL_STATE, {
  [ Types.FETCH_LINES_DATA ]: fetchLinesData,
  [ Types.FETCH_LINES_SUCCESS ]: fetchLinesSuccess,
  [ Types.FETCH_LINES_FAILURE ]: fetchLinesFailure,
} )
