
const dataSchema = {
    required:[],
    dataStructure:{
        id:null,
        bus_number: null,
        chasec_id: null,
        day_out_insurance: null,
        driver: null,
        gps_box: null,
        image: null,
        insurance_company: null,
        license_plate: null,
        line: null,
        number_insurance: null,
        phone_insurance: null,
        responsible: null,
    },
    properties:{
            id: {
                key:"id",
                type: "integer",
                label: "ID"
            },
            bus_number: {
                key:"bus_number",
                type: "string",
                label: "Bus number",
                max_length: 50
            },
            license_plate: {
                key:"license_plate",
                type: "string",
                label: "License plate",
                max_length: 50
            },
            driver: {
                key:"driver",
                type: "integer",
                label: "Driver"
            },
            image: {
                key:"image",
                type: "image upload",
                label: "Image"
            },
            chasec_id: {
                key:"chasec_id",
                type: "string",
                label: "Chasec id",
                max_length: 100
            },
            gps_box: {
                key:"gps_box",
                type: "string",
                label: "Gps box",
                max_length: 100
            },
            responsible: {
                key:"responsible",
                type: "string",
                label: "Responsible",
                max_length: 100
            },
            line: {
                key:"line",
                type: "integer",
                label: "Line"
            },
            number_insurance: {
                key:"number_insurance",
                type: "string",
                label: "Number insurance",
                max_length: 100
            },
            insurance_company: {
                key:"insurance_company",
                type: "string",
                label: "Insurance company",
                max_length: 255
            },
            phone_insurance: {
                key:"phone_insurance",
                type: "string",
                label: "Phone insurance",
                max_length: 10
            },
            day_out_insurance: {
                key:"day_out_insurance",
                type: "datetime",
                format: "date-time",
                label: "Day out insurance"
            }
    }
}


export const schema = dataSchema