import React, { Component } from "react"
import { Button
        , Modal
        , Form
        , Input
        , DatePicker
        , AutoComplete
      , Row ,Col} from 'antd';
const { Option} = AutoComplete;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  
  class extends React.Component {
    
    render() {
      const { visible, onCancel, onCreate, form, dataSource, onChangeDate , onchangeInput, onchangeAuto} = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const options = dataSource.map(item => (
        <Option value={item.name} key={item.id}>
        <span>{item.name}</span>
        </Option>
        ))
      return (
        <Modal
          width={860}
          visible={visible}
          footer={false}
          title="บันทึกบัตรโดยสาร"
          onCancel={onCancel}
        >
          <div style={{marginTop:10,marginBottom:10}}>
          <Form {...formItemLayout}>
            <Form.Item label="Serial number">
              {getFieldDecorator('serial_number')(<Input style={{width:300}}  placeholder="Serial number" type="text" onChange={(event)=>{onchangeInput(event,"serial_number")}} />)}
            </Form.Item>
            <Form.Item label="Tag">
              {getFieldDecorator('tag')(<Input style={{width:300}}  placeholder="Tag" type="text" onChange={(event)=>{onchangeInput(event,"tag")}} />)}
            </Form.Item>
            <Form.Item label="วันที่ผลิต">
              {getFieldDecorator('issued_date')(<DatePicker format="YYYY-MM-DD" style={{width:300}} onChange={(date,dateString)=>{onChangeDate(dateString,"issued_date")}} />)}
            </Form.Item>
            <Form.Item label="วันที่หมดอายุ">
              {getFieldDecorator('valid_until')(<DatePicker format="YYYY-MM-DD" style={{width:300}} onChange={(date,dateString)=>{onChangeDate(dateString,"valid_until")}} />)}
            </Form.Item>
            <Form.Item label="เงินคงเหลือ">
              {getFieldDecorator('purse')( <Input style={{width:300}}  placeholder="เงินคงเหลือ" type="number" onChange={(event)=>{onchangeInput(event,"purse")}} />)}
            </Form.Item>
            <Form.Item label="โบนัส">
              {getFieldDecorator('bonus')( <Input style={{width:300}}  placeholder="โบนัส" type="number" onChange={(event)=>{onchangeInput(event,"bonus")}} />)}
            </Form.Item>
            <Form.Item label="Deposit">
              {getFieldDecorator('deposit')( <Input style={{width:300}}  placeholder="Deposit" type="number" onChange={(event)=>{onchangeInput(event,"deposit")}} />)}
            </Form.Item>
            <Form.Item label="ประเภทบัตร">
              {getFieldDecorator('cardtype',)(<AutoComplete
                                    style={{ width: 300 }}
                                    dataSource={options}
                                    placeholder="ประเภทบัตร"
                                    optionLabelProp = "value"
                                    onChange={(event,options)=>{onchangeAuto(event,"card_type_name",options.key)}}
                                  />)}
                                
            </Form.Item>
            
          </Form>
            <Row gutter={32} style={{marginTop:15,marginBottom:15}}>
                      <Col style={{textAlign:'right'}} xs={{ span: 16, offset: 4 }} >
                          <div style={{marginRight:20}}>
                              <Button style={{width:100}} type="danger" onClick={onCancel} >ยกเลิก</Button> 
                              {'  '}
                              <Button style={{width:100}} onClick={onCreate} type="primary">บันทึก</Button>
                          </div>
                      </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  },
);

class ModalAdd extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        dataFromParent:props.dataFromParent,
        visible: false,
        dataInForm:{}
    }
}
 
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    setTimeout(()=>{
      this.props.callback(this.state.dataInForm)
    },1000)
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onChangeDate = (dateString,key) => {
    let dataObj = this.state.dataInForm
    let date = new Date(dateString);
    let dateStr = date.toISOString()
    dataObj[key] = dateStr
    this.setState({dataInForm:dataObj})
  }

  onchangeInput = (_event,_key) => {
    let data = this.state.dataInForm
    data[_key] = (parseInt(_event.target.value))
    this.setState({dataInForm:data})
  }

  onchangeAuto = (_event,_key,id) => {
    let data = this.state.dataInForm
    data[_key] = (_event)
    data["card_type"] = id
    this.setState({dataInForm:data})
  }

  render() {
    return (
      <div>
        <Button type="primary" style={{float: "right", marginLeft: 15}} onClick={this.showModal}>
          เพิ่มรายการ
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          dataSource={this.props.dataFromParent}
          onChangeDate={this.onChangeDate}
          onchangeInput={this.onchangeInput}
          onchangeAuto={this.onchangeAuto}
        />
      </div>
    );
  }
}
export default ModalAdd;