import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Main } from "./main";
import { Error404 } from "./containers/error404";
import { Signin } from "./containers/signin";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

class App extends Component {
  render() {
    return (
      <HashRouter>
          <Switch>
            <Route exact path='/login' component={Signin}/>
            <Route exact path="/error404" name="Page 404" component={Error404} />
            <Route path='/' name="main" component={Main}/>
          </Switch>
      </HashRouter>
    );
  }
}

export default App;
