import React , { Component } from "react"
import { isEmpty } from '../../lib/utils'
import { connect } from 'react-redux'
import Session,{ getItem } from '../../lib/session'
import MapView from '../../components/mapView'
import { Card, Row, Col,} from 'antd'
import service from '../../services/callbus'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, dateFilter, selectFilter} from 'react-bootstrap-table2-filter';
import DataGrid from '../../components/dataGrid'

function dateFormatter(cell, row) {

  return (
    <span> {  new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB') } </span>
  );
}

const columns = [
  {
    dataField: 'called_date',
    text: 'Call Date Time',
    sort: true,
    formatter: dateFormatter,
    filter: dateFilter(),
  },
  {
    dataField: 'name',
    text: 'Customer Name',
    align: 'center',
  },
  {
    dataField: 'phone',
    text: 'Phone',
    align: 'right',
  },{
    dataField: 'bus_stop_name',
    text: 'Bus Stop',
    align: 'center',
    sort: true,
  },
  {
    dataField: 'latitude',
    text: 'Latitude',
    align: 'right'
  },
  {
    dataField: 'longitude',
    text: 'Longitude',
    align: 'right',
  },
]

class CallBus extends Component {

  constructor(props){
      super(props)
      this.state = {
        objectlist: [],
        limit: 10,
        offset:0,
        filter_string: "",
        totalSize:0,
        sizePerPage:10,
        page:1,
        loading: true
      }
  }

  componentDidMount(){

      if(isEmpty(getItem(Session.header))){
          this.props.history.push('/login')
      }else{
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
          }
        },800)
      }
  }

  spinLoading = (_bool) => {
    this.setState({loading:_bool})
  }

  init_data = (_token) => {
    this.spinLoading(true)
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option(this.props.auth.token,obj).then((res,err) => {

        if(res.status === 200){
          this.setState({
            objectlist: res.data.results,
            totalSize: res.data.count
          })
          this.spinLoading(false)
        }else{
          console.log("get data error !!")
        }
    })

  }

  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option).then((res,err )=> {
        if (res.status){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }
      })
    })
  }

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

    this.spinLoading(true)

    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' || type === 'sort' ){
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }

  DataGridCallback = ( _row, _index ) => {}

  render(){

    let { objectlist , totalSize , page , limit } = this.state

    return(
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <MapView dataList={objectlist}/>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <h2>ตำแหน่งที่มีการเรียกรถ</h2>
          </Col>
        </Row>
        <br/>
        <Row gutter={16}>
          <Col span={24}>
            <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
          </Col>
        </Row>
      </div>
    );
  }

}

const mapStateToProps = state => {
  return { auth: state.auth}
}

export default connect( mapStateToProps )( CallBus );
