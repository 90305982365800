import React, { Component } from "react"
import { Select} from 'antd';
const { Option} = Select;

class FilterBus extends React.Component {
    constructor(props){
      super(props)
      this.state = {
          Bus: "",
      }
    }
   
    onchangeSelect = (_event) => {
      this.setState({Bus: _event})
      setTimeout(()=>{
        this.props.callbacklist(this.state.Bus)
      },1000)
    }
  
    render() {
        const options = this.props.Bus_list.map(item => (
            <Option value={item.bus_number} >
            {item.bus_number}
            </Option>
            ))
        
      return (
        <div>
              <Select style={{ width: 150 }} 
                       onChange={this.onchangeSelect}
                       placeholder="เลือกหมายเลขรถ">
                       { options }
               </Select>
        </div>
      );
    }
  }
  export default FilterBus;