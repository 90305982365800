import React, { Component } from "react"
import { Redirect, Route, Switch, } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Menu, Icon, } from 'antd'
import routes from '../routers/router'
import { isEmpty } from '../lib/utils'
import Session, { getItem , clear } from '../lib/session'
import authAction from '../redux/auth/authRedux'
import menuAction from '../redux/menu/menuRedux'
import Logo from '../components/logo'
import Sidebar from './sidebar'
import serviceAuth from '../services/auth'
import {BaseMediaUrl} from '../config/configUrl'
import { Modal, Button } from 'antd'

const confirm = Modal.confirm

const { Header, Content, Footer, Sider, } = Layout;
const SubMenu = Menu.SubMenu;

class Main extends Component {

  constructor(props) {
    super(props)
    this.state = {
      collapsed: false
    }
  }

  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      
      this.props.history.push( '/login' )
    } else {
        this.funcGetUserSession()
        if(this.props.auth.token !== null){
          this.props.fetchMenu( this.props.auth.token )
          this.props.history.push( '/dashboard' )
        }
      
    }

  }

  componentDidUpdate( preProps ) {

    if ( preProps.auth.token !== this.props.auth.token ) {
      this.props.fetchMenu( this.props.auth.token )
    }

  }

  funcGetUserSession() {
    // return new Promise( ( resolve, reject ) => {
    // })
    this.props.requestSetUser( getItem( Session.header ) )
  }

  toggle = () => {
    this.setState( {
      collapsed: !this.state.collapsed
    } );
  }

  funcLogout = () => {

    // console.log("funcLogout on ")
    setTimeout(()=>{
        serviceAuth.Signout(this.props.auth.token).then((res,err)=>{
          // console.log("res => ",res)
          if(res.status === 200){
              clear()
              window.document.location.reload()
          }else{
              console.log("logout false")
          }

        })
    },500)

  }

  callbackSidebar = (_action) =>{

    const _this = this

    if(_action === 'logout'){
      confirm({
        title: " ออกจากระบบ ",
        content: ' ',
        okText: 'ตกลง',
        okType: 'primary',
        cancelText: 'ไม่',
        cancelType:'danger',
        onOk(){
          _this.funcLogout()
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

  }

  render() {

    const { menus } = this.props

    return (
    <Layout style={{
        minHeight:'100vh',
      }}>
      <Sider trigger={null} collapsible="collapsible" collapsed={this.state.collapsed}>
        <div style={{
            textAlign: "center",
            margin: 10,
          }}>
          <h4 style={{
              color: "#fff"
            }}>
            {
              (
                menus.payLoad !== null && menus.isLoading === false
                ? <img src={BaseMediaUrl + menus.payLoad[ 0 ].provider.logo} className={'img-fluid'} alt="Yunai.asia"/>
                : null
              )
            }
            <div className={'ant-layout-sider-collapsed nav-text'}>
            {
              (
                menus.payLoad !== null && menus.isLoading === false
                ? menus.payLoad[ 0 ].provider.name
                : null
              )
            }
            </div>
            </h4>
            <div className={'ant-layout-sider-collapsed nav-text'}>
              <a href={
                (
                  menus.payLoad !== null && menus.isLoading === false
                  ? menus.payLoad[ 0 ].provider.youtube
                  : null
                )
              } target="_blank"><Icon type="youtube" style={{ fontSize: '28px', color: '#fff' , padding: 5}}/></a>
              <a href={
                (
                  menus.payLoad !== null && menus.isLoading === false
                  ? menus.payLoad[ 0 ].provider.facebook
                  : null
                )
              } target="_blank"><Icon type="facebook" style={{ fontSize: '28px', color: '#fff', padding: 5 }}/></a>

            </div>
        </div>
        {
          (
            menus.payLoad !== null && menus.isLoading === false
            ? <Sidebar callback={this.callbackSidebar} menus={menus.payLoad[ 0 ]}/>
            : null)
        }

      </Sider>
      <Layout>
        <Header style={{
            background: '#fff',
            padding: 0,
            height: 48,
          }}>
          <Icon style={{
              marginLeft: 20,
              paddingTop: 4,
            }} className="trigger" type={this.state.collapsed
              ? 'menu-unfold'
              : 'menu-fold'} onClick={this.toggle}/>
        </Header>
        <Content style={{
            padding: 24,
            background: '#fff',
          }}>
          <Switch>
            {
              routes.map( ( route, idx ) => {
                return route.component
                  ? ( <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => ( <route.component {...props}/> )}/> )
                  : ( null );
              } )
            }
          </Switch>
        </Content>
        <Footer style={{
            textAlign: 'center'
          }}>
          <Logo width="35px"/>
          <strong>YUNAI.ASIA &copy; Jump Up Co., Ltd.</strong>
        </Footer>
      </Layout>
    </Layout> );
  }

}

const mapStateToProps = state => {
  return { auth: state.auth, menus: state.menus, }
}

const mapDispatchToProps = dispatch => {
  return {
    requestSetUser: ( _userObj ) => {
      dispatch( authAction.loginSuccess( _userObj ) )
    },
    fetchMenu: ( _token ) => {
      dispatch( menuAction.fetchMenuData( _token ) )
    },
  }
}
export default connect( mapStateToProps, mapDispatchToProps )( Main );
