import { createActions, createReducer, } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_DRIVERTABLE_REDUX]'

const functionDefinitions = {
  fetchDriverData: ['token'],
  fetchDriverSuccess: ["fetchDriverSuccess"],
  fetchDriverFailure: [ "fetchDriverFailure" ]
}

const { Types, Creators, } = createActions( functionDefinitions )

export const ActionType = Types;
export default Creators;

const initState = {
  isLoading: false,
  payLoad: null,
  error: null,
}

export const INITIAL_STATE = Immutable( initState )

export const fetchDriverData = ( state, action ) => {
  return state.merge( { isLoading: true, payLoad: action.token, error: null, } );
}

export const fetchDriverSuccess = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: action.fetchDriverSuccess, error: null, } );
}

export const fetchDriverFailure = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: null, error: action.fetchDriverFailure, } );
};

export const reducer = createReducer( INITIAL_STATE, {
  [ Types.FETCH_DRIVER_DATA ]: fetchDriverData,
  [ Types.FETCH_DRIVER_SUCCESS ]: fetchDriverSuccess,
  [ Types.FETCH_DRIVER_FAILURE ]: fetchDriverFailure,
} )
