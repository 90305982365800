import axios from 'axios'
import { BaseUrl } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var ServiceAuth = {

    Signin:(_username,_password)=>{
        return new Promise((resolve, reject)=> {

            const headers = {
                'Content-Type':'application/json',
                'apikey': Key,
            }

            const dataObj = {
                username:_username,
	            password:_password
            }

            axios.post((BaseUrl + apiVersion) + 'auth/login/',dataObj,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })
    },

    Signout:(_token)=>{

        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key,
        }

        return new Promise((resolve, reject)=> {
            axios.post((BaseUrl + apiVersion) + 'auth/logout/',{},{headers:headers})
            .then((response)=> {
                resolve(response)
            })
            .catch((error)=> {
                reject(error)
            });
        })
    }

}
export default ServiceAuth;
