import React, { Component } from "react"
import { Modal, Button } from 'antd'

class ModalAlertMessage extends Component {

    constructor(props){
        super(props)
        this.state = {
            title:undefined,
            content:undefined,
            type:undefined
        }
    }

    componentDidMount(){
        this.props.onRef(this)
    }
    
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    init_modal = (type,title,content) => {
        this.setState({
            title:title,
            content:content,
            type:type
        })
    }


    info = (title,content) => {
        let _props = this.props
        Modal.info({
          title: title,
          content: (
            <div>
                {content}
            </div>
          ),
          onOk() {_props.callback()}
        });
    }

    success = (title,content) => {
        let _props = this.props
        Modal.success({
            title: title,
            content: content,
            onOk() {_props.callback()}
        });
    }

    error = (title,content) => {
        let _props = this.props
        Modal.error({
            title: title,
            content: content,
            onOk() {_props.callback()}
        });
    }

    warning = (title,content) => {
        let _props = this.props
        Modal.warning({
            title: title,
            content: content,
            onOk() {_props.callback()}
        });
    }

    render(){

        const { title , content , type  } = this.state
        console.log()

        return(
            <div>
                
                {(type === 'info'?
                    this.info(title,content)
                :null)}

                {(type === 'success'?
                    this.success(title,content)
                :null)}

                {(type === 'error'?
                    this.error(title,content)
                :null)}

                {(type === 'warning'?
                    this.warning(title,content)
                :null)}

            </div>
        );

    }

}

export default ModalAlertMessage;