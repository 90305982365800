import axios from 'axios'
import { BaseUrl  } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var ServiceLine = {

    getListLine:(_token)=>{

        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key,
        }

        return new Promise((resolve, reject)=> {

            axios.get((BaseUrl +apiVersion)+'lines/',{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })
    },

    AddLine:(_token,data)=>{
        delete data.id
        console.log("AddLine => ",data)
        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key
        }

        return new Promise((resolve, reject)=> {
            axios.post((BaseUrl +apiVersion)+'lines/',data,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })

    },

    FullUpdateLine:(_token,data)=>{
        const id = data.id
        delete data.id

        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key
        }
        console.log("FullUpdateLine => data=> ",data)
        return new Promise((resolve, reject)=> {
            axios.put((BaseUrl +apiVersion)+'lines/'+id+'/',data,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })

    },

    DeleteLine:(_token,_id)=>{

        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key
        }

        return new Promise((resolve, reject)=> {
            axios.delete((BaseUrl +apiVersion)+'lines/'+_id+'/',{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })

    },

    getListLineLimit:(_token,_offset,_limit)=>{
        const headers = {
            'Content-Type':'application/json',
            'Authorization':('Token '+_token),
            'apikey':Key,
        }

        return new Promise((resolve, reject)=> {
            axios.get((BaseUrl +apiVersion)+'lines/?limit='+_limit+'&offset='+_offset,{headers:headers})
            .then((response)=> {
                resolve({status:true,data:response})
            })
            .catch((error)=> {
                resolve({status:false,error:error})
            });
        })
    },

    getListOption: ( _token , _option ) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': ( 'Token ' + _token ),
          'apikey': Key
        }
    
        return new Promise( ( resolve, reject ) => {
          axios.get( ( BaseUrl  + apiVersion ) + 'lines/?limit='+_option.limit+'&offset='+_option.offset+_option.filter_string, { headers: headers } ).then( ( response ) => {
            resolve( { status: true, data: response, } )
          } ).catch( ( error ) => {
            resolve( { status: false, error: error, } )
          } );
        } )
    
      }

}
export default ServiceLine;
