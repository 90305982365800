import { all, call, put, takeLatest, select } from "redux-saga/effects"
import serviceMenu from "../../services/menu"
import Actions, { ActionType } from "./menuRedux"

export function* fetchMenuData(action) {

    const tag = "[FETCH_MENU_DATA]";
    try {

        const response = yield call(
            serviceMenu.GetMenu,
            action.token
        );
        
        if(response.status){
            yield put(Actions.fetchMenuSuccess(response.data.data))
        }else{
            console.log(`${tag} response error`) 
            yield put(Actions.fetchMenuFailure(response.error))
        }
        
    } catch (e) {
        console.log(`${tag} error`) 
    }
}

export default function* rootSaga() {
    yield all([
      takeLatest(ActionType.FETCH_MENU_DATA,fetchMenuData)
    ]);
}