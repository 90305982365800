import React, { Component } from "react"
import { Button
        , Modal
        , Form
        , Input
        , DatePicker
        , AutoComplete
      , Row ,Col} from 'antd';
const { Option} = AutoComplete;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  
  class extends React.Component {
    
    render() {
      const { visible, onCancel, onCreate, form, dataSource, onChangeDate , onchangeInput, onchangeAuto} = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
      const options = dataSource.map(item => (
        <Option value={item.serial_number} key={item.id}>
        <span>{item.serial_number}</span>
        </Option>
        ))
      return (
        <Modal
          width={860}
          visible={visible}
          footer={false}
          title="ลงทะเบียนบัตร"
          onCancel={onCancel}
        >
          <div style={{marginTop:10,marginBottom:10}}>
          <Form {...formItemLayout}>
            <Form.Item label="Serial Number">
              {getFieldDecorator('card',)(<AutoComplete
                                    style={{ width: 300 }}
                                    dataSource={options}
                                    placeholder="Serial Number"
                                    optionLabelProp = "value"
                                    onChange={(event,options)=>{onchangeAuto(event,"card",options.key)}}
                                  />)}
            </Form.Item>
            <Form.Item label="เลขบัตรประชาชน">
              {getFieldDecorator('idenity_card_number')( <Input style={{width:300}} type="text"  placeholder="เลขบัตรประชาชน"  onChange={(event)=>{onchangeInput(event,"idenity_card_number")}} />)}
            </Form.Item>
            <Form.Item label="รหัสหลังบัตรประชาชน">
              {getFieldDecorator('idenity_card_back_number')(<Input style={{width:300}} type="text"  placeholder="รหัสหลังบัตรประชาชน"  onChange={(event)=>{onchangeInput(event,"idenity_card_back_number")}} />)}
            </Form.Item>
            <Form.Item label="วันที่ออกบัตรประชาชน">
              {getFieldDecorator('idenity_card_issued_date')(<DatePicker format="YYYY-MM-DD" style={{width:300}} onChange={(date,dateString)=>{onChangeDate(dateString,"idenity_card_issued_date")}} />)}
            </Form.Item>
            <Form.Item label="วันที่หมดอายุบัตรประชาชน">
              {getFieldDecorator('idenity_card_expired_date')(<DatePicker format="YYYY-MM-DD" style={{width:300}} onChange={(date,dateString)=>{onChangeDate(dateString,"idenity_card_expired_date")}} />)}
            </Form.Item>
            <Form.Item label="คำนำหน้า">
              {getFieldDecorator('title')(<Input style={{width:300}}  placeholder="คำนำหน้า" type="text"  onChange={(event)=>{onchangeInput(event,"title")}} />)}
            </Form.Item>
            <Form.Item label="ชื่อ">
              {getFieldDecorator('first_name')(<Input style={{width:300}}  placeholder="ชื่อ" type="text"  onChange={(event)=>{onchangeInput(event,"first_name")}} />)}
            </Form.Item>
            <Form.Item label="นามสกุล">
              {getFieldDecorator('last_name')(<Input style={{width:300}}  placeholder="นามสกุล" type="text"  onChange={(event)=>{onchangeInput(event,"last_name")}} />)}
            </Form.Item>
            <Form.Item label="เบอร์โทรศัพท์">
              {getFieldDecorator('phone_number')(<Input style={{width:300}}  placeholder="เบอร์โทรศัพท์" type="text"  onChange={(event)=>{onchangeInput(event,"phone_number")}} />)}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email')(<Input style={{width:300}}  placeholder="Email" type="text"  onChange={(event)=>{onchangeInput(event,"email")}} />)}
            </Form.Item>
            <Form.Item label="line_id">
              {getFieldDecorator('line_id')(<Input style={{width:300}}  placeholder="line_id" type="text"  onChange={(event)=>{onchangeInput(event,"line_id")}} />)}
            </Form.Item>
            <Form.Item label="facebook_id">
              {getFieldDecorator('facebook_id')(<Input style={{width:300}}  placeholder="facebook_id" type="text"  onChange={(event)=>{onchangeInput(event,"facebook_id")}} />)}
            </Form.Item>
            <Form.Item label="address">
              {getFieldDecorator('address')(<Input style={{width:300}}  placeholder="address" type="text" onChange={(event)=>{onchangeInput(event,"address")}} />)}
            </Form.Item>
          </Form>
            <Row gutter={32} style={{marginTop:15,marginBottom:15}}>
                      <Col style={{textAlign:'right'}} xs={{ span: 16, offset: 4 }} >
                          <div style={{marginRight:20}}>
                              <Button style={{width:100}} type="danger" onClick={onCancel} >ยกเลิก</Button> 
                              {'  '}
                              <Button style={{width:100}} onClick={onCreate} type="primary">บันทึก</Button>
                          </div>
                      </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  },
);

class ModalForcard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        dataFromParent:props.dataFromParent,
        visible: false,
        dataInForm:{}
    }
}
 
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    setTimeout(()=>{
      this.props.callback(this.state.dataInForm)
    },1000)
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onChangeDate = (dateString,key) => {
    let dataObj = this.state.dataInForm
    let date = new Date(dateString);
    let dateStr = date.toISOString()
    dataObj[key] = dateStr
    this.setState({dataInForm:dataObj})
  }

  onchangeInput = (_event,_key) => {
    let data = this.state.dataInForm
    data[_key] = (_event.target.value)
    this.setState({dataInForm:data})
  }

  onchangeAuto = (_event,_key,_id) => {
    let data = this.state.dataInForm
    data[_key] = (_id)
    this.setState({dataInForm: data})
    console.log(this.state.dataInForm)
  }

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal} style={{float:"right"}}>
          ลงทะเบียนบัตร
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          dataSource={this.props.dataFromParent}
          onChangeDate={this.onChangeDate}
          onchangeInput={this.onchangeInput}
          onchangeAuto={this.onchangeAuto}
        />
      </div>
    );
  }
}
export default ModalForcard;