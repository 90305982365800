import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, } from 'antd'
import { Spin, Icon } from 'antd'
import { Button, message } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, } from 'react-bootstrap-table2-filter'
import service from '../../services/card'
import { schema } from '../../formSchema/card/cardSchema'
import { topupSchema } from '../../formSchema/topup/topupSchema'
import action from '../../redux/card/cardTable/cardTableRedux'
import serviceCardtype from '../../services/cardtype'
import ModalAdd from '../../formSchema/card/cardModal'


function dateFormatter(cell, row) {

  if (cell == null){
    return (
      <span> - </span>
    );
  }
  return (
    <span> {  new Date(cell).toLocaleDateString('en-GB')  } </span>
  );
}


const columns = [
  {
    dataField: 'id',
    text: 'Card ID',
    sort: false,
    hidden: true
  }, {
    dataField: 'serial_number',
    text: 'Card Serial Number',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'tag',
    text: 'RFID tag',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'register_date',
    text: 'วันที่ลงทะเบียน',
    sort: true,
    formatter: dateFormatter,
    filter: textFilter(),
  },{
    dataField: 'first_used_date',
    text: 'วันแรกที่ใช้',
    sort: true,
    formatter: dateFormatter,
    filter: textFilter(),
  },
   {
    dataField: 'valid_until',
    text: 'Vaid Until',
    sort: true,
    formatter: dateFormatter,
    filter: textFilter(),
  }, {
    dataField: 'sequence_number',
    text: 'ลำดับธุรกรรม',
    sort: true
  }, {
    dataField: 'purse',
    text: 'ยอดธุรกรรม',
    sort: true
  }, {
    dataField: 'bonus',
    text: 'โบนัส',
    sort: true
  }, {
    dataField: 'card_type_name',
    text: 'ประเภทของการ์ด',
    sort: true,
    filter: textFilter(),
  },

];

class Card extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      objectlist: [],
      limit: 10,
      offset:0,
      filter_string: "",
      totalSize:0,
      sizePerPage:10,
      page:1,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Delete: "Delete",
        Show: "Show",
      },
      loading: false,
      cardtype: []
    }
  }

  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      this.props.history.push( '/login' )
    } else {

      setTimeout(()=>{
        if(this.props.auth.token !== null){
            this.init_data(this.props.auth.token)
            this.cardtype_data(this.props.auth.token)
        }
      },800)

    }

  }
  spinLoading = (_bool) => {
    this.setState({loading:_bool})
  }

  init_data = (_token) => {
    this.spinLoading(true)
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option(this.props.auth.token,obj).then((res,err) => {

        if(res.status === 200){
          this.setState({
            objectlist: res.data.results,
            totalSize: res.data.count
          })
          this.spinLoading(false)
        }else{
          console.log("get data error !!")
        }
    })
  }

  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option).then((res,err)=> {

        if (res.status){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }

      })
    })
  }

  cardtype_data = (_token) => {
    this.get_cardtype_data(_token).then((res,err) => {
      if(res.status === 200){
        this.setState({ cardtype: res.data.results})
      }else{
        console.log("get data error !!")
      }
    })
  }

  get_cardtype_data = (_token) => {
    return new Promise( ( resolve, reject ) => {
      serviceCardtype.getList(_token).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }

  formatData = ( _dataObj, _row ) => {
    return new Promise( ( resolve, reject ) => {
      let results = {}
      Object.keys( _dataObj ).forEach( ( row, index ) => {

        results[ row ] = _row[ row ]

        if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
          resolve( results )
        }

      } );
    } )
  }

  handleClickAdd = () => {

    let dataForChild = {
      dataObj: {
        results: schema.dataStructure,
        dataDefault: schema,
        schema: schema,
        idx: null,
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    }

    this.myChildForAdd.initFormModal( dataForChild )
  }

  handleClickTopup = () => {

    let dataForChild = {
      dataObj: {
        results: topupSchema.dataStructure,
        dataDefault: topupSchema,
        schema: topupSchema,
        idx: null,
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    }

    this.myChildForAddTopup.initFormModal( dataForChild )
  }

  

  funcCallBackModalAdd = ( _dataCallBack ) => {
    console.log( _dataCallBack )
    this.funcAdd( _dataCallBack )
  }

  funcAdd = ( _dataObj ) => {
    service.AddCard( this.props.auth.token, _dataObj ).then( ( res, err ) => {
      console.log(res.status)
        if(res.status){
          console.log("success")
          message.success('เพิ่มข้อมูลสำเร็จ');

      }else{
          console.log("error")
          message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
      }
  } )
  }

  funcCallBackModalTopup = ( _dataCallBack ) => {
    this.funcAddTopup( _dataCallBack )
  }

  funcAddTopup = ( _dataObj ) => {
    console.log("funcAddTopup => ",_dataObj)
      service.AddTopup( this.props.auth.token, _dataObj ).then( ( res, err ) => {
          console.log(res.status)
          if(res.status){
            console.log("success")
            message.success('เติมเงินสำเร็จ');
            this.myChildForAddTopup.handleCancel()
        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งค่ะ');
        }
      })
  }

  

  funcCallBackModalUpdate = ( _dataCallBack ) => {
    this.funcFullUpdate( _dataCallBack )
  }

  funcFullUpdate = ( _dataObj ) => {
    console.log(" funcFullUpdate _dataObj => ",_dataObj)
    // serviceBus.FullUpdateBus( this.props.auth.token, _dataObj ).then( ( res, err ) => {
    //   console.log( "res => ", res )
    // } )
  }

  funcCallbackDelete = ( _id ) => {
    console.log("funcCallbackDelete on id => ",_id)
    // serviceBus.DeleteBus( this.props.auth.token, _id ).then( ( res, err ) => {
    //   console.log( "res => ", res )
    // } )
  }

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
    // console.log("PaginationCallback => on ")
    // console.log({type , _limit, _offset , _filter_string , _page})
    this.spinLoading(true)
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' || type === 'sort' ){
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }


  DataGridCallback  = ( _row, _index ) => {
    // console.log("DataGridCallback => ",_row)
    // console.log("DataGridCallback index  => ",_index)
    this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {

      let dataForChild = {
        dataObj: {
          results: res,
          dataDefault: res,
          schema: schema,
          idx: _index,
          selectoption: {data: this.state.cardtype,
                         key: "id",
                         value: "name"},
        },
        formObj: {
          formState: this.state.formAllState.Update,
          formAllState: this.state.formAllState,
        },
      }

      this.myChildFormModal.initFormModal( dataForChild )
    } )

  }
  modalMessageCallback = () => {
    console.log("modalMessageCallback")
    this.myChildForAdd.handleCancel()
    this.myChildFormModal.handleCancel()
    this.myChildForAddTopup.handleCancel()
  }


  render() {

    let { objectlist , totalSize , page , limit } = this.state

    return (
            <Spin spinning={this.state.loading} delay={300} tip="Loading...">
            <div>
              <Row gutter={16}>
                <Col span={24}>
                  <h2>ข้อมูลบัตรโดยสาร</h2>
                </Col>
              </Row>
              <br/>
              <Row gutter={16}>
                <Col span={24}>
                <ModalAdd dataFromParent={this.state.cardtype} callback={this.funcCallBackModalAdd}/>
                  <Button onClick={this.handleClickTopup} style={{float: "right"}} type="primary"><Icon type="plus" />เติมเงิน</Button>
                </Col>
              </Row>
              <br/>
              <Row gutter={16}>
                <Col span={24}>
                  {
                    (
                      objectlist.length === 0
                      ? null
                      : <div>
                        <FormModal title="Card Form" onRef={refs => ( this.myChildFormModal = refs )} callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete} />
                        <FormModal callback={this.funcCallBackModalTopup} title="เติมเงิน" onRef={refs => ( this.myChildForAddTopup = refs )}/>
                      </div>)
                  }
                  <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
                </Col>
              </Row>
            </div>
            </Spin>
    );

  }

}

const mapStateToProps = state => {
  return { auth: state.auth, card: state.card, }
}

export default connect( mapStateToProps )( Card );
