import { all, call, put, takeLatest, select } from "redux-saga/effects"
import Service from "../../../services/bus_stop"
import Actions, { ActionType } from "./bus_stopTableRedux"

export function* fetchBusstopData( action ) {

  const tag = "[FETCH_BUSSTOP_DATA]";

  try {
    const response = yield call( Service.getListBusStop, action.token );

    if ( response.status ) {
      yield put( Actions.fetchBusstopSuccess( response.data.data ) )
    } else {
      console.log( `${ tag } response error` )
      yield put( Actions.fetchBusstopFailure( response.error ) )
    }

  } catch ( e ) {
    console.log( `${ tag } error` )
  }

}

export default function* rootSaga() {
  yield all( [takeLatest( ActionType.FETCH_BUSSTOP_DATA, fetchBusstopData )] );
}
