import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import {BaseMapUrl} from '../../config/configUrl'
import {MapKey} from '../../config/apikey'
import "./mapView.css";

type State = {
  lat: number,
  lng: number,
  zoom: number,
}

class MapView extends Component {

  constructor(props){
      super(props)
      this.state = {
        data: props.dataList,
        lat: 17.4175641,
        lng: 102.7725176,
        zoom: 13,
      }
  }

  render() {
    const position = [this.state.lat, this.state.lng]
    return (
          <Map center={position} zoom={this.state.zoom}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | Jump Up Co.,Ltd. Tile Server'
              url={BaseMapUrl + "?apikey=" + MapKey}
            />
            {
              this.props.dataList.map( (item) => {
                const point = [item.latitude,item.longitude];
                return(<Marker position={point}>
                  <Popup>
                    {item.name} {item.phone}
                  </Popup>
                </Marker>)
              })
            }

          </Map>
    )
  }
}
export default MapView;
