import { createActions, createReducer } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_BUSTABLE_REDUX]'

const functionDefinitions = {
    fetchBusData: ['token'],
    fetchBusSuccess: ["fetchBusSuccess"],
    fetchBusFailure: ["fetchBusFailure"],
    fetchBusAgain: ["fetchBusAgain"],
}

const { Types, Creators } = createActions(functionDefinitions)

export const ActionType = Types;
export default Creators;

const initState = {
    isLoading:false,
    payLoad:null,
    error:null
}

export const INITIAL_STATE = Immutable(initState)

export const fetchBusData = (state, action) => {
    return state.merge({
        isLoading:true,
        payLoad:action.token,
        error:null
    });
}

export const fetchBusSuccess = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:action.fetchBusSuccess,
        error:null
    });
}

export const fetchBusFailure = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:null,
        error:action.fetchBusSuccess
    });
};

export const fetchBusAgain = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:action.fetchBusAgain,
        fetchDataAgain:true,
        error:null
    });
}

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FETCH_BUS_DATA]: fetchBusData,
    [Types.FETCH_BUS_SUCCESS]: fetchBusSuccess,
    [Types.FETCH_BUS_FAILURE]: fetchBusFailure,
    [Types.FETCH_BUS_AGAIN]: fetchBusAgain,
})