const mapKey = {
  key:"HIgF50ihvnOvGRe2z5ScxYid6UDJYerI"
}

const apiKeyDev = {
  key:"RBKuM7jGU1FonC42wfD1g01Nw3Ct4nHU"
}

const apiKeyProd = {
  key:"ThJDtsJpbKpY8ogTH9RS3DiCd5qmS9BH"
}


export const Key  =  apiKeyProd.key
export const MapKey = mapKey.key
