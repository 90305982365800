import axios from 'axios'
import { BaseUrl } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var ServiceMenu = {

    GetMenu:(_token)=>{
        return new Promise((resolve, reject) => {

            const headers = {
                'Content-Type':'application/json',
                'Authorization':('Token '+_token),
                'apikey': Key
            } 

            axios.get((BaseUrl + apiVersion)+'auth/user/menus/',{headers:headers})
            .then((response)=> {
                resolve({status:true, data:response})
            })
            .catch((error)=> {
                resolve({status:false, error:error})
            });
        })
    }

}

export default ServiceMenu;
