import { createActions, createReducer } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_MENU_REDUX]'

const functionDefinitions = {
    fetchMenuData: ['token'],
    fetchMenuSuccess: ["fetchMenuSuccess"],
    fetchMenuFailure: ["fetchMenuFailure"],
}

const { Types, Creators } = createActions(functionDefinitions)

export const ActionType = Types;
export default Creators;

const initState = {
    isLoading:false,
    payLoad:null,
    error:null
}

export const INITIAL_STATE = Immutable(initState)

export const fetchMenuData = (state, action) => {
    return state.merge({
        isLoading:true,
        payLoad:action.token,
        error:null
    });
}

export const fetchMenuSuccess = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:action.fetchMenuSuccess,
        error:null
    });
}

export const fetchMenuFailure = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:null,
        error:action.fetchMenuSuccess
    });
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FETCH_MENU_DATA]: fetchMenuData,
    [Types.FETCH_MENU_SUCCESS]: fetchMenuSuccess,
    [Types.FETCH_MENU_FAILURE]: fetchMenuFailure
})