const dataSchema = {
    required:[],
    dataStructure:{
        id: null,
        employee_number: null,
        idenity_card_number: null,
        idenity_card_back_number: null,
        idenity_card_issue_date: null,
        idenity_card_expired_date: null,
        title: null,
        first_name: null,
        last_name: null,
        first_name_en: null,
        last_name_en: null,
        phone_number: null,
        address: null,
        company: null,
        card: null
    },
    properties:{
        id: {
            key:"id",
            type: "integer",
            label: "ID",
        },
        employee_number: {
            key:"employee_number",
            type: "string",
            label: "เลขพนักงาน",
        },
        idenity_card_number: {
            key:"idenity_card_number",
            type: "string",
            label: "หมายเลขบัตร"
        },
        idenity_card_back_number: {
            key:"idenity_card_back_number",
            type: "string",
            label: "รหัสหลังบัตร"
        },
        idenity_card_issue_date: {
            key:"idenity_card_issue_date",
            type: "datetime",
            label: "วันที่ลงทะเบียนบัตร"
        },
        idenity_card_expired_date: {
            key:"idenity_card_expired_date",
            type: "datetime",
            label: "วันที่บัตรหมดอายุ"
        },
        title: {
            key:"title",
            type: "string",
            label: "title"
        },
        first_name: {
            key:"first_name",
            type: "string",
            label: "ชื่อ"
        },
        last_name: {
            key:"last_name",
            type: "string",
            label: "นามสกุล"
        },
        first_name_en: {
            key:"first_name_en",
            type: "string",
            label: "ชื่อ (ภาษาอังกฤษ)"
        },
        last_name_en: {
            key:"last_name_en",
            type: "string",
            label: "นามสกุล (ภาษาอังกฤษ)"
        },
        phone_number: {
            key:"phone_number",
            type: "string",
            label: "เบอร์โทรศัพท์"
        },
        address: {
            key:"address",
            type: "string",
            label: "ที่อยู่"
        },
        company: {
            key:"company",
            type: "string",
            label: "บริษัท"
        },
        card: {
            key:"card",
            type: "string",
            label: "บัตร"
        }
    }
}
export const schema = dataSchema