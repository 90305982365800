import { all, call, put, takeLatest, select } from "redux-saga/effects"
import serviceAuth from "../../services/auth"
import Actions, { ActionType } from "./authRedux"

export function* requestLogin(action) {
    const tag = "[REQUEST_LOGIN]";
    try {

        const response = yield call(
            serviceAuth.Signin,
            action.username,
            action.password
        );
        if(response.status){
            yield put(Actions.loginSuccess(response.data.data))
        }else{
            console.log(`${tag} response error`) 
            yield put(Actions.loginFailure(response.error))
        }
        
    } catch (e) {
        console.log(`${tag} error`) 
    }
}

export default function* rootSaga() {
    yield all([
      takeLatest(ActionType.REQUEST_LOGIN, requestLogin)
    ]);
}