import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import service from '../../services/reportBusSummary'
import Session, { getItem } from '../../lib/session'
import { isEmpty } from '../../lib/utils'
import ChartColumn from '../../components/chartColumn'
import DateRange from '../../components/dateRange'

const chartDesc = [
  {
    xLine: "card",
    color: "#f0bb29"
  },
  {
    xLine: "cash",
    color: "#787910"
  }
];

class SummaryChart extends Component {
    constructor( props ) {
		super( props )
		this.state = {
			objectlist:[],
            startdate:'',
            enddate: '',
            limit: 10,
            offset:0,
            totalSize:0,
            sizePerPage:10,
            page:1,
            filter_string: "",
            loading: false,
            date: [],
            chartdata: []
		}
	}

    componentDidMount() {
		if ( isEmpty( getItem( Session.header ) ) ) {
            this.props.history.push( '/login' )
          } else {
            let tmp_date = new Date();
            let tmp_date2 = new Date();
            tmp_date2.setDate(tmp_date2.getDate() - 15)
            let curr_date = tmp_date.getFullYear() + '-' + (tmp_date.getMonth()+1) + '-' + tmp_date.getDate()
            let past_date = tmp_date2.getFullYear() + '-' + (tmp_date2.getMonth()+1) + '-' + (tmp_date2.getDate())
            this.setState({
              startdate: new Date(past_date),
              enddate: new Date(curr_date)
            })
            setTimeout(()=>{
              if(this.props.auth.token !== null){
                  //this.init_data(this.props.auth.token)
                  this.dateRender()
              }
            },800)
          }

    }

    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }

    init_data = (_token) => {
      let alldata =[]
      for(var w=0; w < this.state.date.length; w++){
        let obj = {
          limit: this.state.limit,
          offset: this.state.offset,
          filter_string: this.state.filter_string,
          startdate: this.state.date[w],
          enddate: this.state.date[w]
        }
    

        this.init_data_option(this.props.auth.token,obj).then((res,err) => {
            if(res.state){
              this.setState({
                objectlist: res.data.data,
                totalSize:res.data.data.length
              })
              alldata.push(this.summaryCash(obj.startdate));
              if(alldata.length === this.state.date.length){
                alldata.sort(function(a, b){return new Date(a.date) - new Date(b.date);});
                this.setState({chartdata:alldata})
                this.spinLoading(false)
              }
            }else{
              console.log("get data error !!")
            }

        })
      }
  }


  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option).then((res,err)=> {
        if (res.status){
          if(res.data.status === 200){
            resolve({state:true,data:res.data})
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }

      })
    })
  }

  dateRangeCallback = ( _dateCallback ) => {
    this.setState({
      
      startdate: new Date(_dateCallback.start_date),
      enddate: new Date(_dateCallback.end_date)
    });
    //this.init_data(this.props.auth.token);
    this.dateRender();
  }

  summaryCash = (date) => {
      var total = 0;
      var card = 0
      var ttt = [];
      for(var i=0; i < this.state.totalSize; i++){
        total += parseInt(this.state.objectlist[i]["total"]);
        card += parseInt(this.state.objectlist[i]["card"]);
      }
      ttt={
        "card": card,
        "cash": total,
        "date": date
      }
     return ttt;
  }

  dateRender = () => {
    this.spinLoading(true)
    var Day = 24*60*60*1000;
    var x = new Date();
    var d;
    var test = [];
    var diffDays = (this.state.enddate.getTime() - this.state.startdate.getTime())/Day;
    for(var i=0;i<= diffDays ;i++)
      {
          x = this.state.startdate.setDate(this.state.startdate.getDate() + i);
          var r = new Date(x)
          d = r.getFullYear() + '-' + (r.getMonth()+1) + '-' + (r.getDate())
          test.push(d)
          this.state.startdate.setDate(this.state.startdate.getDate() - i);
      }
      this.setState({date: test});
      this.init_data(this.props.auth.token);
  }


    render() {
        const { objectlist, loading, chartdata } = this.state
        return (
          <Spin spinning={loading} delay={300} tip="Loading...">
            <div style={{overflowX:"scroll"}}>
				<Row gutter={16}>
					<Col span={24}>
						<h2>กราฟรายรับย้อนหลัง</h2>
					</Col>
				</Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <DateRange callback={this.dateRangeCallback} />
                    </Col>
                </Row>
				<br/>
				<Row gutter={16}>
					<Col span={24} >
          <ChartColumn data={chartdata} chartdata={chartDesc} yLine="date" width={1500} height={400} />
					</Col>
				</Row>
			</div>
      </Spin>
        );
    }
}

const mapStateToProps = state => {
	return { auth: state.auth }
}
export default connect( mapStateToProps )( SummaryChart );