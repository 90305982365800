import { createActions, createReducer } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_AUTH_REDUX]'

const functionDefinitions = {
    requestLogin: ["username", "password"],
    loginSuccess: ["loginResponse"],
    loginFailure: ["loginError"],
    requestLogout: null,
}

const { Types, Creators } = createActions(functionDefinitions);

export const ActionType = Types;
export default Creators;

const LOGOUT_STATE = {
    username: null,
    password: null,
    user: {
      email:null,
      groups:null,
      id:null,
      username:null
    },
    loginError: null,
    loginFetching: false,
    token: null
    
};
  
export const InitState = { ...LOGOUT_STATE };
export const INITIAL_STATE = Immutable(InitState);

export const requestLogin = (state, action) => {
    return state.merge({
      loginFetching: true,
      username: action.email,
      password: action.password,
      user: {
        email:null,
        groups:null,
        id:null,
        username:null
      },
      loginError: null
    });
};

export const loginSuccess = (state, action) => {
    // console.log("loginSuccess => action => ",action)
    if(action.loginResponse.key === undefined){
        return state.merge({
          loginFetching: false,
          token: action.loginResponse.token,
          user: {
            email:action.loginResponse.user.email,
            groups:action.loginResponse.user.groups,
            id:action.loginResponse.user.id,
            username:action.loginResponse.user.username
          },
          password: null
        });
    }else{
          return state.merge({
            loginFetching: false,
            token: action.loginResponse.key,
            user: {
              email:action.loginResponse.user.email,
              groups:action.loginResponse.user.groups,
              id:action.loginResponse.user.id,
              username:action.loginResponse.user.username
            },
            password: null
          });
    }

};

export const loginFailure = (state, action) => {
  // console.log("loginFailure => action => ",action)
    return state.merge({
      loginFetching: false,
      loginError: action.loginError,
      password: null
    });
};
  
export const requestLogout = state => {
    return state.merge(LOGOUT_STATE);
};
  
export const reducer = createReducer(INITIAL_STATE, {
    [Types.REQUEST_LOGIN]: requestLogin,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,
    [Types.REQUEST_LOGOUT]: requestLogout
})

