import { createActions, createReducer } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_BUS_FORM_REDUX]'

const functionDefinitions = {
    fetchBusFormData: ['token'],
    fetchBusFormSuccess: ["fetchBusSuccess"],
    fetchBusFormFailure: ["fetchBusFailure"],
}

const { Types, Creators } = createActions(functionDefinitions)

export const ActionType = Types;
export default Creators;

const initState = {
    isLoading:false,
    payLoad:null,
    error:null
}

export const INITIAL_STATE = Immutable(initState)

export const fetchBusFormData = (state, action) => {
    return state.merge({
        isLoading:true,
        payLoad:action.token,
        error:null
    });
}

export const fetchBusFormSuccess = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:action.fetchBusSuccess,
        error:null
    });
}

export const fetchBusFormFailure = (state, action) => {
    return state.merge({
        isLoading:false,
        payLoad:null,
        error:action.fetchBusSuccess
    });
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.FETCH_BUS_FORM_DATA]: fetchBusFormData,
    [Types.FETCH_BUS_FORM_SUCCESS]: fetchBusFormSuccess,
    [Types.FETCH_BUS_FORM_FAILURE]: fetchBusFormFailure
})