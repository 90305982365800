const dataSchema = {
    required: [],
    dataStructure: {
      id: null,
      name: null,
      name_eng: null,
      latitude: null,
      longitude: null,
      radius: null
    },
    properties: {
      id: {
        key: 'id',
        type: 'integer',
        label: 'ID',
      },
      name: {
        key: 'name',
        type: 'string',
        label: 'ชื่อจุดจอด',
      },
      name_eng: {
        key: 'name_eng',
        type: 'string',
        label: 'ชื่อจุดจอด (ภาษาอังกฤษ)',
        max_length: '10',
      },
      latitude: {
        key: 'latitude',
        type: 'integer',
        label: 'ละติจูด',
      },
      longitude: {
        key: 'longitude',
        type: 'integer',
        label: 'ลองจิจูด',
      },
      radius:{
        key: 'radius',
        type: 'integer',
        label: 'รัศมี',
      }
    },
}
  
export const schema = dataSchema
  