import { createActions, createReducer, } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_CARDTABLE_REDUX]'

const functionDefinitions = {
  fetchCardData: ['token'],
  fetchCardSuccess: ["fetchCardSuccess"],
  fetchCardFailure: [ "fetchCardFailure" ]
}

const { Types, Creators, } = createActions( functionDefinitions )

export const ActionType = Types;
export default Creators;

const initState = {
  isLoading: false,
  payLoad: null,
  error: null,
}

export const INITIAL_STATE = Immutable( initState )

export const fetchCardData = ( state, action ) => {
  return state.merge( { isLoading: true, payLoad: action.token, error: null, } );
}

export const fetchCardSuccess = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: action.fetchCardSuccess, error: null, } );
}

export const fetchCardFailure = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: null, error: action.fetchCardFailure, } );
};

export const reducer = createReducer( INITIAL_STATE, {
  [ Types.FETCH_CARD_DATA ]: fetchCardData,
  [ Types.FETCH_CARD_SUCCESS ]: fetchCardSuccess,
  [ Types.FETCH_CARD_FAILURE ]: fetchCardFailure,
} )
