import axios from 'axios'
import { BaseUrl  } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var Service = {

  getList: ( _token, _offset, _limit ) => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key,
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'transaction-coins/?limit=1000',  { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error } )
      } );
    } )
  },

  getListOption: ( _token , _option, _carno ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'transaction-coins/?limit='+_option.limit+'&offset='+_option.offset+_option.filter_string, { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error, } )
      } );
    } )

  }

};

export default Service;
