import React , { Component } from "react";

class Error404 extends Component {

    constructor(pros){
        super(pros)
        this.state = {

        }
    }



    render(){

        return(
            <div style={{ minHeight: '100vh',marginTop:100 }}>
                <center><strong>Error404 : Not found</strong></center>
            </div>
        );
    }

}

export default Error404;
