import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import DataGrid from '../../components/dataGrid'
import { isEmpty } from '../../lib/utils'
import service from '../../services/reportTransationCard'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, selectFilter} from 'react-bootstrap-table2-filter';
import DataGridwithHeader from '../../components/dataGridwithHeader'


function dateFormatter(cell, row) {

	return (
		<span> {  new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB') } </span>
	);
}

function priceFormatter(cell, row) {
	var formatter = new Intl.NumberFormat('th', {
		style: 'currency',
		currency: 'THB',
	});
	return (
		<span>{ formatter.format(cell) } </span>
	);
}

var topup_type_dict = {
	1: "เติมกระเป๋า",
	2: "เติมโบนัส"
};

var direction_dict = {
	'inbound': "ไป",
	'outbound': "กลับ"
};

function directionFormatter(cell,row){
	return (direction_dict[cell]);
}

function topupTypeFormatter(cell,row){
	return (topup_type_dict[cell]);
}

var transaction_type_dict = {
	TOPUP: "เติมเงิน",
	DEDUCT: "หักเงิน",
	REBATE: "ทอนเงิน"
};

function tranTypeFormatter(cell,row){
	return (transaction_type_dict[cell]);
}

const columns = [
	{
		dataField: 'id',
		text: 'ID',
		sort: true,
		hidden: true,
		footer: '',
	},
	{
		dataField: 'transaction_datetime',
		text: 'วันที่บันทึกรายการ',
		filter: dateFilter(),
		formatter: dateFormatter,
		sort: true,
		footer: '',
	},
	{
		dataField: 'cache_transaction_datetime',
		text: 'วันที่เกิดธุรกรรม',
		filter: dateFilter(),
		formatter: dateFormatter,
		sort: true,
		footer: '',
	},
	{
		dataField: 'card_serial_number',
		text: 'Card Serial Number',
		sort: false,
		filter: textFilter(),
		footer: '',
	},
	{
		dataField: 'card_sequence_number',
		text: 'seq.',
		sort: true,
		footer: '',
	},
	{
		dataField: 'transaction_type',
		text: 'ประเภทธุรกรรม',
		formatter: tranTypeFormatter,
		sort: true,
		footer: '',
	},
	{
	 dataField: 'transaction_value',
	 text: 'ยอดธุรกรรม',
	 footer: '',
	},
	{
	 dataField: 'rebate_value',
	 text: 'เป็นทอนเงิน',
	 footer: '',
	},
	{
		dataField: 'transaction_purse_value',
		text: 'จากกระเป๋า/เข้ากระเป๋า',
		sort: true,
		formatter: priceFormatter,
		footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
	},
	{
		dataField: 'transaction_bonus_value',
		text: 'จากโบนัส/เข้าโบนัส',
		sort: true,
		formatter: priceFormatter,
		footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
	},
	{
		dataField: 'purse_value',
		text: 'กระเป๋าคงเหลือ',
		sort: true,
		formatter: priceFormatter,
		footer: '',
		//footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
	},
	{
	 dataField: 'bonus_value',
	 text: 'โบนัสคงเหลือ',
	 sort: true,
	 formatter: priceFormatter,
	 footer: '',
	 //footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
 },
 {
	 dataField: 'topup_type',
	 text: 'ชนิดการเติมเงิน',
	 formatter: topupTypeFormatter,
	 sort: true,
	 footer: '',
 },
 {
	dataField: 'bus.bus_number',
	text: 'เบอร์รถ',
	footer: '',
},
 {
	 dataField: 'driver_name',
	 text: 'ชื่อ พขร.',
	 footer: '',
 },
 {
	 dataField: 'entry_direction',
	 text: 'ขาไป/กลับ',
	 formatter: directionFormatter,
	 footer: '',
 },
 {
	 dataField: 'entry_route.name',
	 text: 'สาย',
	 footer: '',
 },
 {
	dataField: 'entry_bus_stop.name',
	text: 'ป้ายขึ้น',
	footer: '',
 },
 {
 dataField: 'exit_bus_stop.name',
 text: 'ป้ายลง',
 footer: '',
 },
	{
		dataField: 'driver_name',
		text: 'ชื่อ พขร.',
		footer: '',
	},
	// {
	// 	dataField: 'gps_latitude',
	// 	text: 'ละติจูด',
	// 	footer: '',
	// },
	// {
	// 	dataField: 'gps_longitude',
	// 	text: 'ลองจิจูด',
	// 	footer: '',
	// },
];



class ReportTransactionCard extends Component {

	constructor( props ) {
		super( props )
		this.state = {
			objectlist:[],
				limit: 20,
				offset:0,
				filter_string: "",
				totalSize:0,
				sizePerPage:20,
				page:1,
				loading: true,
				btnTopup: null,
				btnDeduct: null,
				btnAll: 'primary',
				reportType:'',
		}
	}

	componentDidMount() {
		if ( isEmpty( getItem( Session.header ) ) ) {
			this.props.history.push( '/login' )
		} else {
			setTimeout(()=>{
				if(this.props.auth.token !== null){
						this.init_data(this.props.auth.token)
				}
			},800)

		}

	}

	init_data = (_token) => {

				let obj = {
					limit: this.state.limit,
					offset: this.state.offset,
					filter_string: this.state.filter_string
				}
				this.spinLoading(true)
				this.init_data_option(this.props.auth.token,obj).then((res,err) => {

						if(res.state){
							this.setState({
								objectlist: res.data.data.results,
								totalSize:res.data.data.count
							})
							this.spinLoading(false)
						}else{
							console.log("get data error !!")
						}

				})

	}


	init_data_option = (_token,_option) => {
		console.log(this.state.reportType)
		return new Promise( ( resolve, reject ) => {
			service.getListOption(_token,_option,this.state.reportType).then((res,err)=> {

				if (res.status){
					if(res.data.status === 200){
						resolve({state:true,data:res.data})
					}
				}else{
					if(res.error.response.status === 401){
							this.props.history.push( '/login' )
					}
				}

			})
		})
	}


	DataGridCallback = ( _row, _index ) => {}

	PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

		let obj = {
			limit: _limit,
			offset: _offset,
			filter_string: _filter_string,
			loading:false
		}

		if(type === 'filter' || type === 'pagination' || type === 'sort'  ){
			this.spinLoading(true)
				this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
					if(res.state){
						this.setState({
							limit: _limit,
							offset: _offset,
							page: _page,
							objectlist: res.data.data.results
						})
						this.spinLoading(false)
					}else{
						console.log("get data error !!")
					}

				})
		}

	}

	handleClickBtnAll = () => {

		this.setState({btnAll:'primary', btnTopup:null,btnDeduct:null,reportType:'',btnAll:'primary'})
		setTimeout(()=>{
			this.init_data(this.props.auth.token)
		},700)

	}

	handleClickBtnTopup = () => {

		this.setState({btnAll:null,btnTopup:'primary',btnDeduct:null,reportType: 'rebate',btnAll:null})
		setTimeout(()=>{
			this.init_data(this.props.auth.token)
		},700)

	}

	spinLoading = (_bool) => {
		this.setState({loading:_bool})
	}

	handleClickBtnDeduct = () => {

		this.setState({btnTopup:null,btnDeduct:'primary',reportType:'deduct' , btnAll:null})
		setTimeout(()=>{
			this.init_data(this.props.auth.token)
		},700)
	}

	render() {

		const { objectlist , totalSize , page , limit ,btnAll, btnTopup , btnDeduct , loading } = this.state

		return (

			<div>
				<Row gutter={16}>
					<Col span={24}>
						<h2>ข้อมูลธุรกรรมของบัตร</h2>
					</Col>
				</Row>
				<br/>
				<Row gutter={16}>
					<Col span={24}>
					</Col>
				</Row>
				<br/>
				<Row gutter={16}>
					<Col span={24}>
						<div>
								<Button onClick={this.handleClickBtnAll} style={{marginRight:10}} type={btnAll} > ทั้งหมด </Button>
								<Button onClick={this.handleClickBtnDeduct} style={{marginRight:10}} type={btnDeduct} > การตัดเงิน </Button>
								<Button onClick={this.handleClickBtnTopup} type={btnTopup} > การเติมเงิน </Button>
						</div>
					</Col>
					<Col span={24}>
						<Spin spinning={loading} delay={300} tip="Loading...">
						<DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
						</Spin>
					</Col>
				</Row>
			</div>

		);
	}
}

const mapStateToProps = state => {
	return { auth: state.auth }
}

const mapDispatchToProps = dispatch => {
	return {
		fetchData: ( token ) => {
			// dispatch(busAction.fetchBusData(token))
		},
		updateBusData: (dataObj) => {
			// console.log("updateBusData => ",dataObj)
			// console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
			// dispatch(busAction.fetchBusAgain(dataObj))
		}
	}
}

export default connect( mapStateToProps, mapDispatchToProps )(ReportTransactionCard);
