import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, message, Spin } from 'antd'
import busAction from '../../redux/bus/busTable/busTableRedux'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import service from '../../services/transactionFarecount'
import { schema } from '../../formSchema/farecount/farecountSchema'
import { Button } from 'antd';
import { isEmpty } from '../../lib/utils'
import { BaseMediaUrl } from '../../config/configUrl'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import servicebus from '../../services/bus'
import ModalAdd from '../../formSchema/farecount/farecountModal'

function dateFormatter(cell, row) {

  if (cell == null){
    return (
      <span> - </span>
    );
  }
  return (
    <span> {  new Date(cell).toLocaleDateString('en-GB') } </span>
  );
}

function priceFormatter(cell, row) {
  var formatter = new Intl.NumberFormat('th', {
    style: 'currency',
    currency: 'THB',
  });
  return (
    <span>{ formatter.format(cell) } </span>
  );
}

const columns = [
  {
    dataField: 'bus',
    text: 'Bus id',
    sort: true,
    footer: ''
  }, {
    dataField: 'start_date',
    text: 'วันที่เริ่มต้น',
    sort: true,
    formatter: dateFormatter,
    footer: ''
  },{
    dataField: 'end_date',
    text: 'วันที่สิ้นสุด',
    sort: true,
    formatter: dateFormatter,
    footer: ''
  },
   {
    dataField: 'coin',
    text: 'เหรียญ',
    sort: true,
    formatter: priceFormatter,
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
  }, {
    dataField: 'bill',
    text: 'ธนบัตร',
    sort: true,
    formatter: priceFormatter,
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
  }, {
    dataField: 'total_amount',
    text: 'ยอดรวมสุทธิ',
    sort: true,
    formatter: priceFormatter,
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0)
  },

];

class Farecount extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      objectlist: [],
      limit: 10,
      offset:0,
      filter_string: "",
      totalSize:0,
      sizePerPage:10,
      page:1,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Delete: "Delete",
        Show: "Show",
      },
      loading: false,
      busnumber: [],
    }
  }

  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      this.props.history.push( '/login' )
    } else {
      this.init_data(this.props.auth.token)
      this.bus_data(this.props.auth.token)
      // this.props.fetchCardData( this.props.auth.token )
    }

  }
  spinLoading = (_bool) => {
    this.setState({loading:_bool})
  }

  init_data = (_token) => {
    this.spinLoading(true)
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option(this.props.auth.token,obj).then((res,err) => {

        if(res.status === 200){
          this.setState({
            objectlist: res.data.results,
            totalSize: res.data.count
          })
          this.spinLoading(false)
        }else{
          console.log("get data error !!")
        }
    })
  }

  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }

  bus_data = (_token) => {
    this.get_bus_data(_token).then((res,err) => {
      if(res.status === 200){
        this.setState({ busnumber: res.data.results})
      }else{
        console.log("get data error !!")
      }
    })
  }

  get_bus_data = (_token) => {
    return new Promise( ( resolve, reject ) => {
      servicebus.getList(_token).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }

  funcAdd = ( _dataObj ) => {
    console.log(_dataObj)
    service.AddFareCount( this.props.auth.token, _dataObj ).then( ( res, err ) => {
        console.log(res.status)
          if(res.status){
            console.log("success")
            message.success('เพิ่มข้อมูลสำเร็จ');

        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
    } )
  }

  funcFullUpdate = ( _dataObj ) => {
    service.FullUpdateFarecount( this.props.auth.token, _dataObj ).then( ( res, err ) => {
      if(res.status){
        console.log("success")
        message.success('แก้ไขข้อมูลสำเร็จ');
        this.myChildFormModal.handleCancel()

    }else{
        console.log("error")
        message.error('เกิดข้อผิดพลาดในการอัพเดท กรุณาลองใหม่อีกครั้งค่ะ');
    }
    } )
  }

  funcCallbackDelete = ( _id ) => {
    service.DeleteFarecount( this.props.auth.token, _id ).then( ( res, err ) => {
      if(res.status){
        console.log("success")
        message.success('ลบข้อมูลสำเร็จ');
        this.myChildFormModal.handleCancel()

    }else{
        console.log("error")
        message.error('เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
    }
      // console.log( "res => ", res )
    } )
  }

  formatData = ( _dataObj, _row ) => {
    return new Promise( ( resolve, reject ) => {
      let results = {}
      Object.keys( _dataObj ).forEach( ( row, index ) => {

        results[ row ] = _row[ row ]

        if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
          resolve( results )
        }

      } );
    } )
  }
/*
  handleClickAddFare = () => {
    let dataForChild = {
      dataObj: {
        results: schema.dataStructure,
        dataDefault: schema,
        schema: schema,
        idx: null
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    }

    this.myChildFormAddFarecount.initFormModal( dataForChild )
  }
  */

  funcCallBackModalAddFareCount = ( _dataCallBack ) => {
    this.funcAdd( _dataCallBack )
  }

  funcCallBackModalUpdate = ( _dataCallBack ) => {
    // console.log(" funcCallBackModalUpdateBus => _dataCallBack => ",_dataCallBack)
    this.funcFullUpdate( _dataCallBack )
  }

  modalMessageCallback = () => {
    // console.log("modalMessageCallback")
    this.myChildFormAddFarecount.handleCancel()
    this.myChildFormModal.handleCancel()
}

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
    // console.log("PaginationCallback => on ")
    // console.log({type , _limit, _offset , _filter_string , _page})
    this.spinLoading(true)
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' ){
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }

  DataGridCallback  = ( _row, _index ) => {
    // console.log("DataGridCallback => ",_row)
    // console.log("DataGridCallback index  => ",_index)
    this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {

      let dataForChild = {
        dataObj: {
          results: res,
          dataDefault: res,
          schema: schema,
          idx: _index,
          selectoption: {data: this.state.busnumber,
                          key: "id",
                          value: "bus_number"},
        },
        formObj: {
          formState: this.state.formAllState.Update,
          formAllState: this.state.formAllState,
        },
      }

      this.myChildFormModal.initFormModal( dataForChild )
    } )

  }


  render() {

    let { objectlist , totalSize , page , limit } = this.state
    return (
        <Spin spinning={this.state.loading} delay={300} tip="Loading...">
            <div>
              <Row gutter={16}>
                <Col span={24}>
                  <h2>บันทึกยอดนับเงิน (Manual)</h2>
                </Col>
              </Row>
              <br/><br/>
              <Row gutter={16}>
                <Col span={24}>
                  <ModalAdd dataFromParent={this.state.busnumber} callback={this.funcCallBackModalAddFareCount}/>
                </Col>
              </Row>
              <br/>
              <Row gutter={16}>
                <Col span={24}>

            {
              (
                objectlist.length === 0 ?
                null
                :
                <div>
                  <FormModal callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete} title="แก้ไขยอดนับเงิน" onRef={refs => ( this.myChildFormModal = refs )}/>
                </div>
              )
            }
                  <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
                </Col>
              </Row>
            </div>
          </Spin>

    );

  }
}



const mapStateToProps = state => {
  return { auth: state.auth, test: state.farecount, }
}



export default connect( mapStateToProps )( Farecount );
