import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import service from '../../services/dashboardSummary'
import Session, { getItem } from '../../lib/session'
import DataGridwithHeader from '../../components/dataGridwithHeader'
import { isEmpty } from '../../lib/utils'

const columns = [
    {
		dataField: 'id',
        text: 'id',
        hidden: true
	},
	{
		dataField: 'busnumber',
        text: 'หมายเลขรถ',
        width: '100',
        rowspan:'2',
        row:'0'
	},
	{
		dataField: 'coins',
        text: 'เหรียญ',
        width: '100',
        rowspan:'2',
        row:'0'
    },
	{
		dataField: 'bill',
        text: 'ธนบัตร',
        width: '100',
        rowspan:'2',
        row:'0'
    },
    {
        text: 'การแตะบัตร',
        colspan: '2',
        row:'0',
        headerAlign:'center'
    },
    {
        dataField: 'card',
        text: 'ยอดเงินในบัตร',
        width: '100',
        row:'1'
    },
    {
        dataField: 'card',
        text: 'โบนัส',
        width: '100',
        row:'1'
    },
    {
		dataField: 'topup',
        text: 'การเติมเงิน',
        width: '100',
        rowspan:'2',
        row:'0'
    },
    {
		dataField: 'total',
        text: 'ยอดรวม',
        width: '100',
        rowspan:'2',
        row:'0'
    }
];

const footerData = [
    [
      {
        columnIndex: 0,
        width: '10'
      },
      {
        columnIndex: 1,
        align: 'right',
        formatter: (tableData) => {
          let label = 0;
          for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
            label += Number(tableData[i].coins);
          }
          return (
            <strong>{ label }</strong>
          );
        }
      },
      {
        columnIndex: 2,
        align: 'right',
        formatter: (tableData) => {
          let label = 0;
          for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
            label += Number(tableData[i].bill);
          }
          return (
            <strong>{ label }</strong>
          );
        }
      },
      {
        columnIndex: 3,
        align: 'right',
        formatter: (tableData) => {
          let label = 0;
          for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
            label += Number(tableData[i].card);
          }
          return (
            <strong>{ label }</strong>
          );
        }
      },
      {
        columnIndex: 4,
        align: 'right',
        formatter: (tableData) => {
          let label = 0;
          for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
            label += Number(tableData[i].card);
          }
          return (
            <strong>{ label }</strong>
          );
        }
      },
      {
        columnIndex: 5,
        align: 'right',
        formatter: (tableData) => {
          let label = 0;
          for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
            label += Number(tableData[i].topup);
          }
          return (
            <strong>{ label }</strong>
          );
        }
      },
      {
        columnIndex: 6,
        align: 'right',
        formatter: (tableData) => {
          let label = 0;
          for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
            label += Number(tableData[i].total);
          }
          return (
            <strong>{ label }</strong>
          );
        }
      }
    ]
  ];
class DailyTransaction extends Component {
    constructor( props ) {
		super( props )
		this.state = {
			objectlist:[],
                reportType:'topup',
                limit: 20,
				offset:0,
				filter_string: "",
				totalSize:0,
				sizePerPage:20,
				page:1,
		}
	}

    componentDidMount() {
		if ( isEmpty( getItem( Session.header ) ) ) {
			this.props.history.push( '/login' )
		} else {
			setTimeout(()=>{
				if(this.props.auth.token !== null){
						//this.init_data(this.props.auth.token)
				}
			},800)

		}

    }
    
    init_data = (_token) => {

        let obj = {
            limit: this.state.limit,
            offset: this.state.offset,
            filter_string: this.state.filter_string
        }
        this.init_data_option(this.props.auth.token,obj).then((res,err) => {

                if(res.state){
                    this.setState({
                        objectlist: res.data.data,
                        totalSize:res.data.data.count
                    })
                }else{
                    console.log("get data error !!")
                }

        })

}


init_data_option = (_token,_option) => {
return new Promise( ( resolve, reject ) => {
    service.getListOption(_token,_option,this.state.reportType).then((res,err)=> {

        if (res.status){
            if(res.data.status === 200){
                resolve({state:true,data:res.data})
            }
        }else{
            if(res.error.response.status === 401){
                    this.props.history.push( '/login' )
            }
        }

    })
})
}

    render() {
        const { objectlist, totalSize } = this.state
        return (
            <div>
				<Row gutter={16}>
					<Col span={24}>
						<h2>รายงานสรุปรวมธุรกรรมประจำวัน</h2>
					</Col>
				</Row>
				<br/>
				<Row gutter={16}>
					<Col span={24}>
              <DataGridwithHeader totalSize={ totalSize } footerData={footerData} dataList="" col={columns}  />
					</Col>
				</Row>
			</div>
          
        );
    }
}

const mapStateToProps = state => {
	return { auth: state.auth }
}
export default connect( mapStateToProps )( DailyTransaction );