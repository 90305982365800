import axios from 'axios'
import { BaseUrl  } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'
var startdate = ''
var enddate = ''

var Service = {

  getList: ( _token ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'transaction-farecounts/?limit=1000', { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error, } )
      } );
    } )

  },

  getListOption: ( _token , _option ) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ( 'Token ' + _token ),
      'apikey': Key
    }

    return new Promise( ( resolve, reject ) => {
      axios.get( ( BaseUrl  + apiVersion ) + 'transaction-farecounts/?limit='+_option.limit+'&offset='+_option.offset+_option.filter_string, { headers: headers } ).then( ( response ) => {
        resolve( { status: true, data: response, } )
      } ).catch( ( error ) => {
        resolve( { status: false, error: error, } )
      } );
    } )

  },

  AddFareCount:(_token,data)=>{
    startdate = data.start_date
    if(data.end_date != null){
      enddate = data.end_date
      data.end_date = enddate.substring(0, 10)
    }
    data.start_date = startdate.substring(0, 10)
    delete data.id
    
    
     console.log(data)
    const headers = {
        'Content-Type':'application/json',
        'Authorization':('Token '+_token),
        'apikey':Key
    }

    return new Promise((resolve, reject)=> {
        axios.post((BaseUrl +apiVersion)+'transaction-farecounts/',data,{headers:headers})
        .then((response)=> {
            resolve({status:true,data:response})
        })
        .catch((error)=> {
            resolve({status:false,error:error})
        });
    })

},
    FullUpdateFarecount:(_token,data)=>{
      console.log(_token,data)
      const id = data.id
      startdate = data.start_date
      data.start_date = startdate.substring(0, 10)
      if(data.end_date != null){
        enddate = data.end_date
        data.end_date = enddate.substring(0, 10)
      }
      delete data.id

      const headers = {
          'Content-Type':'application/json',
          'Authorization':('Token '+_token),
          'apikey':Key
      }

      return new Promise((resolve, reject)=> {
          axios.put((BaseUrl +apiVersion)+'transaction-farecounts/'+id+'/',data,{headers:headers})
          .then((response)=> {
              resolve({status:true,data:response})
          })
          .catch((error)=> {
              resolve({status:false,error:error})
          });
      })

    },
    DeleteFarecount:(_token,_id)=>{

      const headers = {
          'Content-Type':'application/json',
          'Authorization':('Token '+_token),
          'apikey':Key
      }

      return new Promise((resolve, reject)=> {
          axios.delete((BaseUrl +apiVersion)+'transaction-farecounts/'+_id+'/',{headers:headers})
          .then((response)=> {
              resolve({status:true,data:response})
          })
          .catch((error)=> {
              resolve({status:false,error:error})
          });
      })

    }


};

export default Service;
