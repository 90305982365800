import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import service from '../../services/reportBusSummary'
import Session, { getItem } from '../../lib/session'
import { isEmpty } from '../../lib/utils'
import ChartColumn from '../../components/chartColumn'
import DateRange from '../../components/dateRange'

const chartdata = [
  {
    xLine: "card",
    color: "#CB8054"
  },
  {
    xLine: "coins",
    color: "#B35340"
  },
  {
    xLine: "bill",
    color: "#6F3637"
  },
  {
    xLine: "total",
    color: "#233342"
  }
];

class TransactionSummaryChart extends Component {
    constructor( props ) {
		super( props )
		this.state = {
			objectlist:[],
            startdate:'',
            enddate: '',
            limit: 10,
            offset:0,
            totalSize:0,
            sizePerPage:10,
            page:1,
            filter_string: "",
            loading: false,
		}
	}

    componentDidMount() {
		if ( isEmpty( getItem( Session.header ) ) ) {
            this.props.history.push( '/login' )
          } else {
            let tmp_date = new Date();
            let curr_date = tmp_date.getFullYear() + '-' + (tmp_date.getMonth()+1) + '-' + tmp_date.getDate()
            this.setState({
              startdate: curr_date,
              enddate: curr_date
            })
            setTimeout(()=>{
              if(this.props.auth.token !== null){
                  this.init_data(this.props.auth.token)
              }
            },800)
          }

    }
    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }
    init_data = (_token) => {
      this.spinLoading(true)
        let obj = {
          limit: this.state.limit,
          offset: this.state.offset,
          filter_string: this.state.filter_string,
          startdate: this.state.startdate,
          enddate: this.state.enddate
        }
    

        this.init_data_option(this.props.auth.token,obj).then((res,err) => {
            if(res.state){
              this.setState({
                objectlist: res.data.data,
                totalSize:res.data.data.length
              })
              this.spinLoading(false)
              console.log(this.state.objectlist)
            }else{
              console.log("get data error !!")
            }

        })

  }


  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option).then((res,err)=> {
        if (res.status){
          if(res.data.status === 200){
            resolve({state:true,data:res.data})
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }

      })
    })
  }

  dataRangeCallback = ( _dateCallback ) => {
    this.setState({
      
      startdate: _dateCallback.start_date,
      enddate: _dateCallback.end_date
    });
    this.init_data(this.props.auth.token);
  }

    render() {
        const { objectlist, loading } = this.state
        return (
          <Spin spinning={loading} delay={300} tip="Loading...">
            <div>
				<Row gutter={16}>
					<Col span={24}>
						<h2>กราฟสรุปรายได้แยกประเภทรายคัน</h2>
					</Col>
				</Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <DateRange callback={this.dataRangeCallback} />
                    </Col>
                </Row>
				<br/>
				<Row gutter={16}>
					<Col span={24} >
          <ChartColumn data={objectlist} chartdata={chartdata} yLine="busnumber" width={1100} height={400} />
					</Col>
				</Row>
			</div>
      </Spin>
        );
    }
}

const mapStateToProps = state => {
	return { auth: state.auth }
}
export default connect( mapStateToProps )( TransactionSummaryChart );