import React, { Component } from "react"
import { Select} from 'antd';
const { Option} = Select;

class SelectLine extends React.Component {
    constructor(props){
      super(props)
      this.state = {
          line: "",
      }
    }
   
    onchangeSelect = (_event, key) => {
      this.setState({line: key.key})
      setTimeout(()=>{
        this.props.callbackline(this.state.line)
      },1000)
    }
  
    render() {
        const options = this.props.line_list.map(item => (
            <Option value={item.name} key={item.id} >
            {item.name}
            </Option>
            ))
        
      return (
        <div>
              <Select style={{ width: 150 }} 
                       onChange={this.onchangeSelect}
                       placeholder="เลือกสายเดินรถ">
                       { options }
               </Select>
        </div>
      );
    }
  }
  export default SelectLine;