const dataSchema = {
    required: [],
    dataStructure: {
      id: null,
      name: null,
      initial_purse: null,
      initial_bonus: null,
      registration_bonus: null,
      topup_bonus_added_when_topup_amount: null,
      topup_bonus: null,
      sale_price: null,
      extend_day_on_topup: null,
      valid_until_days_after_register: null,
      default_fare: null,
      status: null
    },
    properties: {
      id: {
        key: 'id',
        type: 'integer',
        label: 'ID',
      },
      name: {
        key: 'name',
        type: 'string',
        label: 'ประเภทบัตร',
      },
      initial_purse: {
        key: 'initial_purse',
        type: 'integer',
        label: 'initial purse',
      },
      initial_bonus: {
        key: 'initial_bonus',
        type: 'integer',
        label: 'initial_bonus',
      },
      registration_bonus: {
        key: 'registration_bonus',
        type: 'integer',
        label: 'โบนัสเมื่อลงทะเบียน',
      },
      topup_bonus_added_when_topup_amount: {
        key: 'topup_bonus_added_when_topup_amount',
        type: 'integer',
        label: 'จำนวนเงินที่ได้โบนัส',
      },
      topup_bonus: {
        key: 'topup_bonus',
        type: 'integer',
        label: 'โบนัสเติมเงิน',
      },
      sale_price: {
        key: 'sale_price',
        type: 'integer',
        label: 'ราคาขาย',
      },
      extend_day_on_topup: {
        key: 'extend_day_on_topup',
        type: 'integer',
        label: 'extend_day_on_topup',
      },
      valid_until_days_after_register: {
        key: 'valid_until_days_after_register',
        type: 'integer',
        label: 'อายุบัตรหลังลงทะเบียน',
      },
      default_fare:{
        key: 'default_fare',
        type: 'integer',
        label: 'ค่าโดยสาร'
      },
      status: {
        key: 'status',
        type: 'integer',
        label: 'status',
      }
    },
  }
  
  export const schema = dataSchema