import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { DatePicker } from 'antd'
import service from '../../services/transactionCoinForChart'
import Session, { getItem } from '../../lib/session'
import { isEmpty } from '../../lib/utils'
import ChartColumn from '../../components/chartColumn'

const chartdata = [
  {
    xLine: "times",
    color: "#F2A04E"
  }
];
class CustomerCNTChart extends Component {
    constructor( props ) {
		super( props )
		this.state = {
			objectlist:[],
            date:'',
            totalSize:0,
            GroupData:[],
            loading: false,
		}
	}

    componentDidMount() {
		if ( isEmpty( getItem( Session.header ) ) ) {
            this.props.history.push( '/login' )
          } else {
            let tmp_date = new Date();
            let curr_date = tmp_date.getFullYear() + '-' + (tmp_date.getMonth()+1) + '-' + tmp_date.getDate()
            this.setState({
              date: curr_date,
            })
            setTimeout(()=>{
              if(this.props.auth.token !== null){
                  this.init_data(this.props.auth.token)
              }
            },800)
          }

    }

    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }
    
    init_data = (_token) => {
      this.spinLoading(true)
        let obj = {
          date: this.state.date
        }
    
        this.init_data_option(this.props.auth.token, obj).then((res,err) => {
            if(res.state){
              this.setState({
                objectlist: res.data.data.results,
              })
              this.addtime()
            }else{
              console.log("get data error !!")
            }

        })

  }


  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getList(_token, _option).then((res,err)=> {
        if (res.status){
          if(res.data.status === 200){
            resolve({state:true,data:res.data})
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }

      })
    })
  }
 
  addtime = () => {
   
    for(var i=0;i<= this.state.objectlist.length-1; i++){
      this.state.objectlist[i]["time"] =  this.state.objectlist[i]["transaction_datetime"].substring(11, 13)+ ":00"
    }
    
    this.timeData()
  }

  timeData = () => {
    Array.prototype.groupBy = function(prop) {
      return this.reduce(function(groups, item) {
        var val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    }
const tmp = this.state.objectlist.groupBy("time");
const test = [];

    for(var j=0; j< 24; j++){
      if(j<10){
        var hour = "0"+j+":00";
      }else{
        var hour = j+":00";
      }
      if(tmp[hour] === undefined){
        var times = 0;
      }else{
        var times = tmp[hour].length;
      }
      test.push({
        "time": hour,
        "times": times
      })

    }
   this.setState({ GroupData: test})
   console.log(test)
   this.spinLoading(false)

  }

  dateCallback = ( date, dateString ) => {
    this.setState({
      
      date: dateString,
    });
    this.init_data(this.props.auth.token);
  }

    render() {
        const { objectlist, GroupData , loading} = this.state
        return (
          <Spin spinning={loading} delay={300} tip="Loading...">
            <div>
				<Row gutter={16}>
					<Col span={24}>
						<h2>กราฟช่วงเวลาที่มีผู้ใช้บริการ</h2>
					</Col>
				</Row>
                <Row gutter={16}>
                    <Col span={24}>
                      <DatePicker onChange={this.dateCallback} />
                    </Col>
                </Row>
				<br/>
				<Row gutter={16}>
					<Col span={24} >
            <ChartColumn data={GroupData} chartdata={chartdata} yLine="time" width={1100} height={400} />
					</Col>
				</Row>
			</div>
      </Spin>
        );
    }
}

const mapStateToProps = state => {
	return { auth: state.auth }
}
export default connect( mapStateToProps )( CustomerCNTChart );