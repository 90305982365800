import React, { Component } from "react"
import { connect } from 'react-redux'
import { Spin } from 'antd'
import { Row, Col, } from 'antd'
import { Button } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import service from '../../services/driver'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ModalAlertMessage from '../../components/modalAlertMessage'
import { schema } from '../../formSchema/driver/driverSchema'
import { BaseMediaUrl } from '../../config/configUrl'

function imageFormatter( cell, row ) {
  return ( <img src={BaseMediaUrl + cell} className="datagrid-image"/> );
}

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    hidden: true,
  }, {
    dataField: 'driver_number',
    text: 'รหัสคนขับ',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'image',
    text: '',
    formatter: imageFormatter,
  },  {
    dataField: 'first_name',
    text: 'ชื่อ',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'last_name',
    text: 'นามสกุล',
    sort: true,
    filter: textFilter(),
  },{
    dataField: 'card_serial_number',
    text: 'หมายเลขการ์ด',
    sort: true,
    filter: textFilter(),
  },{
    dataField: 'license_type',
    text: 'ประเภทใบขับขี่',
    sort: true,
    filter: textFilter(),
  }, {
    dataField: 'license_number',
    text: 'หมายเลขใบขับขี่',
    sort: true,
    filter: textFilter(),
  },
];

class Driver extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      objectlist: [],
      limit: 10,
      offset:0,
      filter_string: "",
      totalSize:0,
      sizePerPage:10,
      page:1,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Delete: "Delete",
        Show: "Show",
      },
      loading: false,
    }
  }

  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      this.props.history.push( '/login' )
    } else {
        setTimeout(()=>{
          if(this.props.auth.token !== null){
            this.init_data(this.props.auth.token)
          }
        },800)
    }

  }

  spinLoading = (_bool) => {
    this.setState({loading:_bool})
  }

  init_data = (_token) => {
    service.getList(_token).then((res,err )=> {
      if(res.data.status === 200){

        let obj = {
          limit: this.state.limit,
          offset: this.state.offset,
          filter_string: this.state.filter_string
        }
        this.spinLoading(true)
        this.init_data_option(this.props.auth.token,obj).then((res2,err2) => {

            if(res2.status === 200){
              this.setState({
                objectlist: res2.data.results,
                totalSize:res.data.data.count
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }

        })

      }
    })
  }

  init_data_option = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option).then((res,err )=> {
        if (res.status){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }
      })
    })
  }

  formatData = ( _dataObj, _row ) => {

    return new Promise( ( resolve, reject ) => {
      let results = {}
      Object.keys( _dataObj ).forEach( ( row, index ) => {

        results[ row ] = _row[ row ]

        if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
          resolve( results )
        }

      } );
    } )

  }

  DataGridCallback = ( _row, _index ) => {

    this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {

      let dataForChild = {
        dataObj: {
          results: res,
          dataDefault: res,
          schema: schema,
          idx: _index,
        },
        formObj: {
          formState: this.state.formAllState.Update,
          formAllState: this.state.formAllState,
        },
      }

      this.myChildFormModal.initFormModal( dataForChild )
    } )

  }

  funcCallbackDelete = ( _id ) => {
    service.DeleteDriver( this.props.auth.token, _id ).then( ( res, err ) => {
      console.log( "res => ", res )
        if(res.status){
            console.log("success")
            let modalType = 'success'
            this.myChildModalMessage.init_modal(modalType,'ลบข้อมูลคนขับ','ระบบทำการลบข้อมูล เรียบร้อยแล้วค่ะ')
        }else{
            console.log("error")
            let modalType = 'error'
            this.myChildModalMessage.init_modal(modalType,'ลบข้อมูลคนขับ','ระบบเกิดข้อผิดพลาดในการลบข้อมูล ลองใหม่อีกครั้งค่ะ')
        }
    } )
  }

  funcFullUpdate = ( _dataObj ) => {
    // console.log(" funcFullUpdate => _dataObj => ",_dataObj)
    service.FullUpdateDriver( this.props.auth.token, _dataObj ).then( ( res, err ) => {
          console.log("res => ",res)
          if(res.status){
              let modalType = 'success'
              this.myChildModalMessage.init_modal(modalType,'แก้ไขข้อมูลคนขับ','ระบบทำการแก้ไขข้อมูล เรียบร้อยแล้วค่ะ')
          }else{
              let modalType = 'error'
              this.myChildModalMessage.init_modal(modalType,'แก้ไขข้อมูลคนขับ','ระบบเกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาตรวจสอบข้อมูล และ ลองใหม่อีกครั้งค่ะ')
          }
      } )
  }

  funcAdd = ( _dataObj ) => {
    service.AddDriver( this.props.auth.token, _dataObj ).then( ( res, err ) => {
        if(res.status){
            let modalType = 'success'
            this.myChildModalMessage.init_modal(modalType,'เพิ่มข้อมูลคนขับ','ระบบทำการเพิ่มข้อมูล เรียบร้อยแล้วค่ะ')
        }else{
            let modalType = 'error'
            this.myChildModalMessage.init_modal(modalType,'เพิ่มข้อมูลคนขับ','ระบบเกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาตรวจสอบข้อมูล และ ลองใหม่อีกครั้งค่ะ')
        }
    } )
  }

  funcCallBackModalAdd = ( _dataCallBack ) => {
    this.funcAdd( _dataCallBack )
  }

  handleClickAdd = () => {

    let dataForChild = {
      dataObj: {
        results: schema.dataStructure,
        dataDefault: schema,
        schema: schema,
        idx: null,
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    }

    this.myChildForAdd.initFormModal( dataForChild )
  }

  funcCallBackModalUpdate = (_dataCallBack) => {
    this.funcFullUpdate( _dataCallBack )
  }

  modalMessageCallback = () => {
    console.log("modalMessageCallback")
    this.myChildForAdd.handleCancel()
    this.myChildFormModal.handleCancel()
  }

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' || type === 'sort' ){
        this.spinLoading(true)
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }

  render() {

    let { objectlist , totalSize , page , limit } = this.state

    return (
    <div>
      <ModalAlertMessage callback={this.modalMessageCallback} onRef={refs => ( this.myChildModalMessage = refs )}  />
        <Spin spinning={this.state.loading} delay={300} tip="Loading...">
          <Row gutter={16}>
            <Col span={24}>
              <h2>ข้อมูลคนขับ</h2>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              <Button onClick={() => {this.handleClickAdd()}} style={{float: "right"}} type="primary">เพิ่มรายการ</Button>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              {
                (
                  objectlist.length === 0
                  ? null
                  : <div>
                    <FormModal title="แก้ไขข้อมูลคนขับ" onRef={refs => ( this.myChildFormModal = refs )} callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete}/>
                    <FormModal callback={this.funcCallBackModalAdd} title="เพิ่มข้อมูลคนขับ" onRef={refs => ( this.myChildForAdd = refs )}/>
                    {/* <DataGrip callback={this.DataGridCallback} columns={columns} dataList={objectlist}/> */}
                  </div>)
              }

              <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
            </Col>
          </Row>
        </Spin>
    </div> );

  }

}

const mapStateToProps = state => {
  return { auth: state.auth, driver: state.driver, }
}

export default connect( mapStateToProps )( Driver );
