import React from "react";
import ReactExport from "react-export-excel";
import {Icon } from 'antd'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "ธุรกรรม",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

var dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];

class ExportExcel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
         
        }
      }

    render() {
        return (
            <ExcelFile element={<button style={{color:'white',float:'right',marginBottom: 10, marginRight: 10}} 
                                    className="btn btn-warning btn-sm">
                                    <Icon type="file-excel" style={{ fontSize: '16px', color: '#fff'}}/>ส่งออกเป็น Excel</button>}>
                <ExcelSheet data={this.props.data} name="1">
                {
                this.props.column.map( item => {
                    return (
                        <ExcelColumn label={item.text} value={item.dataField} />
                    );
                })
                }
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
export default ExportExcel;