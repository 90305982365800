const dataSchema = {
    required:[],
    dataStructure:{
        id: null,
        bus: null,
        start_date: null,
        end_date: null,
        coin: null,
        bill: null,
        total_amount: null,
    },
    properties:{
        id: {
            key:"id",
            type: "integer",
            label: "ID",
        },
        bus: {
            key:"bus",
            type: "string",
            label: "bus id",
        },
        start_date: {
            key:"start_date",
            type: "datetime",
            label: "วันที่เริ่ม"
        },
        end_date: {
            key:"end_date",
            type: "datetime",
            label: "วันที่สิ้นสุด"
        },
        coin: {
            key:"coin",
            type: "integer",
            label: "เหรียญ"
        },
        bill: {
            key:"bill",
            type: "integer",
            label: "ธนบัตร"
        },
        total_amount: {
            key:"total_amount",
            type: "integer",
            label: "ยอดรวมสุทธิ"
        }
    }
}
export const schema = dataSchema