import React, {Component} from "react"
import {Icon, } from 'antd'
import ExportExcel from '../exportExcel'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, {textFilter, Comparator} from 'react-bootstrap-table2-filter'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import "./dataGrid.css";
const { ExportCSVButton } = CSVExport;

const columns = [
  {
    dataField: 'id',
    text: 'Product ID',
  }, {
    dataField: 'name',
    text: 'Product Name',
    filter: textFilter()
  }, {
    dataField: 'price',
    text: 'Product Price'
  }, {
    dataField: 'total',
    text: 'Product Total'
  }, {
    dataField: 'uid',
    text: 'Product Uid'
  },
]
const products = [
  {
    id: 5,
    name: 'Natd1',
    price: 2000,
    total: 2000,
    uid: "1234",
  }, {
    id: 6,
    name: 'Natd2',
    price: 1000,
    total: 2000,
    uid: "1543",
  }, {
    id: 7,
    name: 'Natd3',
    price: 2000,
    total: 2000,
    uid: "1234",
  }, {
    id: 8,
    name: 'Natd4',
    price: 1700,
    total: 2500,
    uid: "1543",
  }, {
    id: 9,
    name: 'Natd2',
    price: 1000,
    total: 2000,
    uid: "1543",
  }, {
    id: 10,
    name: 'Natd3',
    price: 2600,
    total: 2400,
    uid: "1234",
  }, {
    id: 11,
    name: 'Natd4',
    price: 1700,
    total: 24500,
    uid: "1543",
  },, {
    id: 12,
    name: 'Natd4',
    price: 1700,
    total: 2500,
    uid: "1543",
  }, {
    id: 13,
    name: 'Natd2',
    price: 1000,
    total: 2000,
    uid: "1543",
  }, {
    id: 14,
    name: 'Natd3',
    price: 2600,
    total: 2400,
    uid: "1234",
  }, {
    id: 15,
    name: 'Natd4',
    price: 1700,
    total: 24500,
    uid: "1543",
  },, {
    id: 16,
    name: 'Natd2',
    price: 1000,
    total: 2000,
    uid: "1543",
  }, {
    id: 17,
    name: 'Natd3',
    price: 2600,
    total: 2400,
    uid: "1234",
  }, {
    id: 18,
    name: 'Natd4',
    price: 1700,
    total: 24500,
    uid: "1543",
  },, {
    id: 19,
    name: 'Natd4',
    price: 1700,
    total: 24500,
    uid: "1543",
  },, {
    id: 20,
    name: 'Natd2',
    price: 1000,
    total: 2000,
    uid: "1543",
  }, {
    id: 21,
    name: 'Natd3',
    price: 2600,
    total: 2400,
    uid: "1234",
  }, {
    id: 22,
    name: 'Natd4',
    price: 1700,
    total: 24500,
    uid: "1543",
  },
]

function priceFormatter(column, colIndex, {sortElement, filterElement}) {
  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {filterElement}
        {column.text}
        {sortElement}
      </div>
  );
}

function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

const customTotal = ( from, to, size ) => ( <span style={{
    marginLeft: 10
  }} className="react-bootstrap-table-pagination-total">
  Showing {from}
  to {to}
  of {size}
  Results
</span> );

const options = {
  paginationSize: 10,
  pageStartIndex: 1,
  // alwaysShowAllBtns: true,  Always show next and previous button
  // withFirstAndLast: false,  Hide the going to First and Last page button
  // hideSizePerPage: true,  Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true,  Hide the pagination list when only one page
  firstPageText: 'หน้าแรก',
  prePageText: 'ก่อนหน้า',
  nextPageText: 'ถัดไป',
  lastPageText: 'ท้ายสุด',
  nextPageTitle: 'หน้าต่อไป',
  prePageTitle: 'Pre page',
  firstPageTitle: 'หน้าต่อไป',
  lastPageTitle: 'หน้าสุดท้าย',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [
    {
      text: '20',
      value: 20,
    }, {
      text: '50',
      value: 50,
    }, {
      text: '100',
      value: 100,
    }, {
      text: '1000',
      value: 1000,
    },{
      text: '10000',
      value: 10000,
    },
  ],
}; // A numeric array is also available. the purpose of above example is custom the text

function extend(obj, src) {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true
};

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  event
}) => {

  return (
<ToolkitProvider
  keyField="id"
  data={ data }
  columns={ columns }
  exportCSV={ { onlyExportSelection: true, exportAll: false } }
>
  {
    props => (
      <div>
        <div style={{float:'left',marginBottom: 10}}> พบ {totalSize} รายการ </div>
        <ExportCSVButton style={{color:'white',float:'right',marginBottom: 10}} className="btn btn-warning btn-sm" { ...props.csvProps }><Icon type="file-excel" style={{ fontSize: '16px', color: '#fff'}}/>ส่งออกเป็น CSV</ExportCSVButton>
        <ExportExcel column={columns} data={data} />
        <BootstrapTable {...props.baseProps } selectRow={ selectRow } rowStyle={{
            backgroundColor: '#f4f4f4'
          }} remote keyField="id" data={data} columns={columns} filter={filterFactory()} pagination={paginationFactory( extend(options,{page, sizePerPage, totalSize }) )} rowEvents={event} onTableChange={onTableChange}/>
      </div>
    )
}
</ToolkitProvider>
  )
};

class DataGrid extends Component {

  constructor(props) {
    super(props)
    this.state = {
      columns: props.columns,
      page: 1,
      data: props.dataList,
      sizePerPage: 20,
      totalSize: props.totalSize
    }
  }

  componentDidMount(){
    // console.log("data grid on")
    // console.log("props dataList => ",this.props.dataList)
    //console.log("dataGrid on => ",this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.dataList,
      totalSize: nextProps.totalSize,
      page: nextProps.page,
      sizePerPage: nextProps.limit
     });
  }

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {

    var filter_string = "";

    // filtering
    for (const dataField in filters) {
      const { filterVal, filterType, comparator } = filters[dataField];

      if (filterType == "DATE")
      {
        if (filterVal.date != null){
          var m = new Date(filterVal.date);
          var dateString = m.getUTCFullYear() +"-"+ pad((m.getUTCMonth()+1),2) +"-"+ pad(m.getUTCDate(),2);
          filter_string += "&" + dataField.toString()+ "=" + dateString;
        }

      }else{
        filter_string += "&" + dataField.toString()+ "=" + filterVal.toString();
      }

    }

    // ordering
    if (sortField){
      if (sortOrder === 'desc'){
        filter_string += "&ordering=-" + sortField.toString();
      }else{
        filter_string += "&ordering=" + sortField.toString();
      }
    }

    const currentIndex = (page - 1) * sizePerPage
    setTimeout(()=>{
      this.props.paginationCallback(type, sizePerPage, currentIndex, filter_string, page);
    },600)

  }

  render() {
    const {data, sizePerPage, totalSize, page, columns} = this.state;
    // console.log("DataGrid Check Data ==> ",data)

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.callback(row, rowIndex)
      },
      onDoubleClick: (e, row, rowIndex) => {
        // console.log("onDoubleClick on")
      },
      onMouseEnter: (e, row, rowIndex) => {
        // console.log("onMouseEnter on")
      },
      onMouseLeave: (e, row, rowIndex) => {
        // console.log("onMouseLeave on")
      }
    };

    return (

      <div style={{
          marginTop: 20,
          marginBottom: 20
        }}>
        <RemotePagination data={data} page={page} sizePerPage={sizePerPage} totalSize={totalSize} onTableChange={this.handleTableChange} columns={columns} event={rowEvents}/>
      </div>);
  }
}
export default DataGrid;
