const dataSchema = {
    required: [],
  dataStructure: {
      card_id: null,
      transaction_value: null
  },
  properties: {
    card_id: {
        key: 'card_id',
        type: 'string',
        label: 'Serial number'
    },
    transaction_value: {
        key: 'transaction_value',
        type: 'string',
        label: 'จำนวนเงิน'
    }
  }
}

export const topupSchema = dataSchema