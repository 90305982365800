import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import createSagaMiddleware from "redux-saga"
import { reducers } from "../redux/reducers"
import rootSaga from "../redux/sagas"

let store = undefined
let sagaMiddleware = createSagaMiddleware()
store = createStore( reducers, applyMiddleware( sagaMiddleware ) )
sagaMiddleware.run( rootSaga )

export {
  store
}
