import { applyMiddleware, combineReducers, compose, createStore, } from "redux"
import createSagaMiddleware from "redux-saga"
import { reducer as auth } from '../redux/auth/authRedux'
import { reducer as menus } from '../redux/menu/menuRedux'
import { reducer as bus } from './bus'
import { reducer as card } from './card'
import { reducer as driver } from './driver'
import { reducer as bus_stop } from './bus_stop'
import { reducer as line } from './line'

export const reducers = combineReducers( {
  auth,
  menus,
  bus,
  card,
  driver,
  bus_stop,
  line
})
