import React, { Component } from "react"
import logo from './logo.png';

class Logo extends Component {

  constructor( props ) {
    super( props )
  }

  render() {
    const { width } = this.props
    return ( <img src={logo} width={width} className="img-fluid" alt="Yunai.asia"/> );
  }
}
export default Logo;
