import React , { Component } from "react"
import { Link } from "react-router-dom";
import { Layout, Menu , Icon } from 'antd'

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Sidebar extends Component {

    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    generate = (_row,_index) => {

        if(_row.menu.sub_menus.length === 0){
            return(
                <Menu.Item  key={`menu${_index}`}>
                    <Icon type="right" />
                    <span>{_row.menu.name}</span>
                    <Link to={_row.menu.route}/>
                </Menu.Item>
            )
        }else{
            return(
                <SubMenu
                key={`sub${_index}`}
                title={<span><Icon type="right" /><span>{_row.menu.name}</span></span>}
                >
                    {_row.menu.sub_menus.map((row2,index2)=>{
                        return(
                            <Menu.Item  key={`buc${index2} ${_row.menu.name}`}><Link to={row2.route}/>{row2.name}</Menu.Item>
                        )
                    })}
                </SubMenu>
            )
        }

    }

    handleClickLogout = () =>{
        this.props.callback("logout")
    }

    render(){

        const { menus } = this.props
        
        return(
            
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                    {menus.menus.map((row,index)=>{
                        return this.generate(row,index)
                    })}
                    <Menu.Item onClick={()=>{this.handleClickLogout()}} key="logout">
                        <Icon type="poweroff" />
                        <span>ออกจากระบบ</span>
                        {/* <Link to={'/login'}/> */}
                    </Menu.Item>
                </Menu>

        );
    }

}


export default (Sidebar);
