import { all, call, put, takeLatest, select } from "redux-saga/effects"
import Service from "../../../services/line"
import Actions, { ActionType } from "./linesRedux"

export function* fetchLinesData( action ) {

  const tag = "[FETCH_LINES_DATA]";

  try {
    const response = yield call( Service.getListLine, action.token );
    // console.log("LINES RES => ",response)
    if ( response.status ) {
      yield put( Actions.fetchLinesSuccess( response.data.data ) )
    } else {
      console.log( `${ tag } response error` )
      yield put( Actions.fetchLinesFailure( response.error ) )
    }

  } catch ( e ) {
    console.log( `${ tag } error` )
  }

}

export default function* rootSaga() {
  yield all( [takeLatest( ActionType.FETCH_LINES_DATA, fetchLinesData )] );
}
