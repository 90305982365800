import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import DataGrid from '../../components/dataGrid'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import service from '../../services/transactionCoinSavemoney'
import servicebus from '../../services/bus'
import FilterBus from '../../components/filterBus'

function dateFormatter(cell, row) {

  return (
    <span> {  new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB') } </span>
  );
}

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    hidden: true,
  },
  {
    dataField: 'savemoney_datetime',
    text: 'วันที่ปิดยอด',
    sort: true,
    formatter: dateFormatter,
    footer: '',
    filter: dateFilter(),
  },
  {
    dataField: 'savemoney_id',
    text: 'เลขลำดับการปิดยอด',
    sort: false,
    footer: '',
    filter: textFilter(),
  },
  {
    dataField: 'savemoney_carno',
    text: 'หมายเลขรถ',
    sort: true,
    footer: '',
    filter: textFilter(),
  },
  {
    dataField: 'savemoney_balance',
    text: 'จำนวนยอดที่ปิด',
    sort: false,
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
  },
  {
    dataField: 'savemoney_status',
    text: 'สถานะ',
    sort: false,
    footer: '',
  },
];

class CoinSave extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      objectlist:[],
        limit: 10,
        offset:0,
        filter_string: "",
        totalSize:0,
        sizePerPage:10,
        page:1,
        loading: true,
        busnumber: [],
        car_no: "",
    }
  }

  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      this.props.history.push( '/login' )
    }else{
      setTimeout(()=>{
        if(this.props.auth.token !== null){
            this.init_data(this.props.auth.token)
            this.bus_data(this.props.auth.token)
        }
      },800)
    }
  }

  spinLoading = (_bool) => {
    this.setState({loading:_bool})
  }


  init_data = (_token) => {
    this.spinLoading(true)

    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option(this.props.auth.token,obj, this.state.car_no).then((res,err) => {

        if(res.status === 200){
          this.setState({
            objectlist: res.data.results,
            totalSize: res.data.count
          })
          this.spinLoading(false)
        }else{
          console.log("get data error !!")
        }
    })
  }

  init_data_option = (_token,_option,_carno) => {
    return new Promise( ( resolve, reject ) => {
      service.getListOption(_token,_option,_carno).then((res,err )=> {
        if (res.status){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }else{
          if(res.error.response.status === 401){
              this.props.history.push( '/login' )
          }
        }
      })
    })
  }

  formatData = ( _dataObj, _row ) => {
    return new Promise( ( resolve, reject ) => {
      let results = {}
      Object.keys( _dataObj ).forEach( ( row, index ) => {

        results[ row ] = _row[ row ]

        if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
          resolve( results )
        }

      } );
    } )
  }

  bus_data = (_token) => {
    this.get_bus_data(_token).then((res,err) => {
      if(res.status === 200){
        console.log(res.data.results)
        this.setState({ busnumber: res.data.results})
      }else{
        console.log("get data error !!")
      }
    })
  }

  get_bus_data = (_token) => {
    return new Promise( ( resolve, reject ) => {
      servicebus.getList(_token).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }

  DataGridCallback = ( _row, _index ) => {}

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
    this.spinLoading(true)

    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'filter' || type === 'pagination' || type === 'sort' ){
        this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
          if(res.status === 200){
            this.setState({
              limit: _limit,
              offset: _offset,
              page: _page,
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }

        })
    }

  }

    changeCallback = (_dataline) => {
      this.setState({car_no: _dataline})
      this.init_data(this.props.auth.token)
    } 

  render() {

    let { objectlist , totalSize , page , limit } = this.state

    return (
      <Spin spinning={this.state.loading} delay={300} tip="Loading..."> <div>
      <Row gutter={16}>
        <Col span={24}>
          <h2>รายงานสรุปยอดปิดตู้รับค่าโดยสาร</h2>
        </Col>
      </Row>
      <br/>
      
      <br/>
      <Row gutter={16}>
        <Col span={24}>
          <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
        </Col>
      </Row>
    </div>
    </Spin>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth}
}


export default connect( mapStateToProps )( CoinSave );
