const dataSchema = {
    required: [],
    dataStructure: {
      id: null,
      name: null,
      description: null,
      color: null,
      text_color: null,
      active: null
    },
    properties: {
      id: {
        key: 'id',
        type: 'integer',
        label: 'ID',
      },
      name: {
        key: 'name',
        type: 'string',
        label: 'ชื่อสาย',
      },
      description: {
        key: 'description',
        type: 'string',
        label: 'รายละเอียด',
      },
      color: {
        key: 'color',
        type: 'string',
        label: 'โค้ดสี',
      },
      text_color: {
        key: 'text_color',
        type: 'string',
        label: 'ชื่อสี',
      },
      active: {
        key: 'active',
        type: 'string',
        label: 'Active',
      }
    },
  }
  
  export const schema = dataSchema
  