import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, } from 'antd'
import { Spin, Icon } from 'antd'
import { Button, message } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, } from 'react-bootstrap-table2-filter'
import service from '../../services/registerCard'
import { schema } from '../../formSchema/cardtype/cardtypeSchema'
import serviceCard from '../../services/card'
import ModalForcard from '../../formSchema/register_card/registerCard'

const columns = [
    {
      dataField: 'id',
      sort: false,
      hidden: true
    },
    {
      dataField: 'serial_number',
      text: 'Serial Number',
      sort: false,
    }, {
      dataField: 'idenity_card_number',
      text: 'เลขบัตรประชาชน',
      sort: true,
      filter: textFilter(),
    }, {
      dataField: 'title',
      text: 'คำนำหน้า',
      sort: true,
    }, {
      dataField: 'first_name',
      text: 'ชื่อ',
      filter: textFilter(),
    },{
      dataField: 'last_name',
      text: 'นามสกุล',
      filter: textFilter(),
    },
     {
      dataField: 'phone_number',
      text: 'เบอร์โทรศัพท์',
      filter: textFilter(),
    }, {
      dataField: 'email',
      text: 'Email',
    }, {
      dataField: 'line_id',
      text: 'Line ID',
    }, {
      dataField: 'facebook_id',
      text: 'Facebook',
    }, {
      dataField: 'address',
      text: 'ที่อยู่',
    }

  ];

  class RegisterCard extends Component {

    constructor( props ) {
      super( props )
      this.state = {
        objectlist:[],
          limit: 20,
          offset:0,
          filter_string: "",
          totalSize:0,
          sizePerPage:20,
          page:1,
          loading: true,
          formAllState: {
            Create: "Create",
            Update: "Update",
            Show: "Show",
          },
          cardid: ''
      }
    }

    componentDidMount() {
      if ( isEmpty( getItem( Session.header ) ) ) {
        this.props.history.push( '/login' )
      } else {
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
              this.card_data(this.props.auth.token)
          }
        },800)

      }

    }

    init_data = (_token) => {

          let obj = {
            limit: this.state.limit,
            offset: this.state.offset,
            filter_string: this.state.filter_string
          }
          this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err) => {

              if(res.state){
                this.setState({
                  objectlist: res.data.data.results,
                  totalSize:res.data.data.count
                })
                this.spinLoading(false)
              }else{
                console.log("get data error !!")
              }

          })

    }


    init_data_option = (_token,_option) => {
      return new Promise( ( resolve, reject ) => {
        service.getListOption(_token,_option).then((res,err)=> {

          if (res.status){
            if(res.data.status === 200){
              resolve({state:true,data:res.data})
            }
          }else{
            if(res.error.response.status === 401){
                this.props.history.push( '/login' )
            }
          }

        })
      })
    }

    card_data = (_token) => {
      this.get_card_data(_token).then((res,err) => {
        if(res.status === 200){
          this.setState({ cardid: res.data.results})
        }else{
          console.log("get data error !!")
        }
      })
    }

    get_card_data = (_token) => {
      return new Promise( ( resolve, reject ) => {
        serviceCard.getList(_token).then((res,err )=> {
          if (res.data != null){
            if(res.data.status === 200){
              resolve(res.data)
            }
          }
        })
      })
    }

    funcCallBackModalRegis = ( _dataCallBack ) => {
      //console.log( _dataCallBack )
      this.funcAddRegis( _dataCallBack )
    }

    funcAddRegis = ( _dataObj ) => {
      service.AddCustomerProfile( this.props.auth.token, _dataObj ).then( ( res, err ) => {
        console.log(res.status)
          if(res.status){
            console.log("success")
            message.success('เพิ่มข้อมูลสำเร็จ');

        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
    } )
    }




    DataGridCallback = ( _row, _index ) => {

      this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {

        let dataForChild = {
          dataObj: {
            results: res,
            dataDefault: res,
            schema: schema,
            idx: _index,
            canDelete: false
          },
          formObj: {
            formState: this.state.formAllState.Update,
            formAllState: this.state.formAllState,
          },
        }

        this.myChildFormModal.initFormModal( dataForChild )
      } )

    }

    PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

      let obj = {
        limit: _limit,
        offset: _offset,
        filter_string: _filter_string,
        loading:false
      }

      if(type === 'filter' || type === 'pagination' || type === 'sort'  ){
        this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
            if(res.state){
              this.setState({
                limit: _limit,
                offset: _offset,
                page: _page,
                objectlist: res.data.data.results
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }

          })
      }

    }

    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }
    formatData = ( _dataObj, _row ) => {

      return new Promise( ( resolve, reject ) => {
        let results = {}
        Object.keys( _dataObj ).forEach( ( row, index ) => {

          results[ row ] = _row[ row ]

          if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
            resolve( results )
          }

        } );
      } )

    }


    render() {
      const { objectlist , totalSize , page , limit , loading, cardid } = this.state
      return (

        <div>
          <Row gutter={16}>
            <Col span={24}>
              <h2>ข้อมูลผู้ลงทะเบียนบัตร</h2>
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>
              {
                (cardid ?
                  <ModalForcard dataFromParent={cardid} callback={this.funcCallBackModalRegis}/>
                  :
                  null
                  )
              }
            </Col>
          </Row>
          <br/>
          <Row gutter={16}>
            <Col span={24}>

              <Spin spinning={loading} delay={300} tip="Loading...">
                <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
              </Spin>
            </Col>
          </Row>
        </div>

      );
    }
  }

  const mapStateToProps = state => {
    return { auth: state.auth }
  }

  const mapDispatchToProps = dispatch => {
    return {
      fetchData: ( token ) => {
        // dispatch(busAction.fetchBusData(token))
      },
      updateBusData: (dataObj) => {
        // console.log("updateBusData => ",dataObj)
        // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
        // dispatch(busAction.fetchBusAgain(dataObj))
      }
    }
  }

  export default connect( mapStateToProps, mapDispatchToProps )(RegisterCard);
