import { all, call, put, takeLatest, select } from "redux-saga/effects"
import Service from "../../../services/driver"
import Actions, { ActionType } from "./driverTableRedux"

export function* fetchDriverData( action ) {

  const tag = "[FETCH_DRIVER_DATA]";

  try {
    const response = yield call( Service.getList, action.token );

    if ( response.status ) {
      yield put( Actions.fetchDriverSuccess( response.data.data ) )
    } else {
      console.log( `${ tag } response error` )
      yield put( Actions.fetchDriverFailure( response.error ) )
    }

  } catch ( e ) {
    console.log( `${ tag } error` )
  }
}

export default function* rootSaga() {
  yield all( [takeLatest( ActionType.FETCH_DRIVER_DATA, fetchDriverData )] );
}
