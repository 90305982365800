const config = {
  dev: "https://apidev.yunai.asia/smartbus/",
  prod: "https://api.yunai.asia/smartbus/",
  maptile: "https://api.yunai.asia/osmtile/v1/{z}/{x}/{y}.png",
  mediaBaseUrlDev: "https://dev.yunai.asia/media/",
  mediaBaseUrlProd: "https://yunai.asia/media/",
}

export const BaseUrl = config.prod
export const BaseMapUrl = config.maptile
export const BaseMediaUrl = config.mediaBaseUrlProd
