import React, { Component } from "react";
import { connect } from 'react-redux'
import { Layout, Menu, Icon, } from 'antd'
import { Row, Col, } from 'antd'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import DataGrid from '../../components/dataGrid'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter, } from 'react-bootstrap-table2-filter';
import { Spin } from 'antd'
import service1 from '../../services/dashboardSummary'
import service2 from '../../services/dashboardLastSaveMoney'
import Clock from 'react-live-clock';
import HeaderDashboard from './headerDashboard'

function priceFormatter(cell, row) {
  var formatter = new Intl.NumberFormat('th', {
    style: 'currency',
    currency: 'THB',
  });
  return (
    <span>{ formatter.format(cell) } </span>
  );
}

const columns = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    footer: '',
    hidden: true,
  },
  {
    dataField: 'busnumber',
    text: 'เบอร์รถ',
    sort: true,
    footer: ''
  },
  {
    dataField: 'card',
    text: 'การแตะบัตร',
    sort: true,
    formatter: priceFormatter,
    align: 'right',
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
    footerAlign: (column, colIndex) => 'right',
  },
  {
    dataField: 'topup',
    text: 'การเติมเงิน',
    sort: true,
    formatter: priceFormatter,
    align: 'right',
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
    footerAlign: (column, colIndex) => 'right',
  },
  {
    dataField: 'coins',
    text: 'เหรียญ',
    sort: true,
    formatter: priceFormatter,
    align: 'right',
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
    footerAlign: (column, colIndex) => 'right',
  },
  {
    dataField: 'bill',
    text: 'ธนบัตร',
    sort: true,
    formatter: priceFormatter,
    align: 'right',
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
    footerAlign: (column, colIndex) => 'right',
  },
  {
    dataField: 'total',
    text: 'ยอดรวม',
    sort: true,
    formatter: priceFormatter,
    align: 'right',
    footer: columnData => columnData.reduce((acc, item) => acc + parseFloat(item), 0),
    footerAlign: (column, colIndex) => 'right',
  },
]

const columns2 = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    footer: '',
    hidden: true,
  }, 
  {
    dataField: 'busnumber',
    text: 'เบอร์รถ',
    sort: true,
  },
  {
    dataField: 'card',
    text: 'การแตะบัตร',
    sort: true,
    align: 'right'
  },
  {
    dataField: 'topup',
    text: 'การเติมเงิน',
    sort: true,
    align: 'right'
  },
  {
    dataField: 'coins',
    text: 'เหรียญ',
    sort: true,
    align: 'right'
  },
  {
    dataField: 'bill',
    text: 'ธนบัตร',
    formatter: priceFormatter,
    sort: true,
    align: 'right'
  },
  {
    dataField: 'total',
    text: 'ยอดรวม',
    sort: true,
    formatter: priceFormatter,
    align: 'right'
  },
  {
    dataField: 'last_savedate',
    text: 'อัพเดทข้อมูลล่าสุด',
    sort: true,
    align: 'center',
  },
]

class Dashboard extends Component {

  constructor( pros ) {
    super( pros )
    this.state = {
      objectlist: [],
      limit: 10,
      offset:0,
      filter_string: "",
      totalSize:0,
      sizePerPage:10,
      page:1,
      objectlist2: [],
      limit2: 10,
      offset2:0,
      filter_string2: "",
      totalSize2:0,
      sizePerPage2:10,
      page2:1,
      loading1: true,
      loading2: true
    }
  }



  componentDidMount() {

    if ( isEmpty( getItem( Session.header ) ) ) {
      this.props.history.push( '/login' )
    }else{
      setTimeout(()=>{
        if(this.props.auth.token !== null){
          this.init_data1(this.props.auth.token)
          this.init_data2(this.props.auth.token)
        }
      },800)
    }

  }

  componentDidUpdate( preProps ) {
    if ( preProps.auth.token !== this.props.auth.token ) {
      this.init_data1(this.props.auth.token)
      this.init_data2(this.props.auth.token)
    }
  }

  spinLoading1 = (_bool) => {
    this.setState({loading1:_bool})
  }

  spinLoading2 = (_bool) => {
    this.setState({loading2:_bool})
  }

  init_data1 = (_token) => {
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option1(this.props.auth.token,obj).then((res,err) => {
        if(res.status === 200){
          this.setState({
            objectlist: res.data,
            totalSize: res.data.length
          })
          this.spinLoading1(false)
        }else{
          console.log("get data error !!")
        }
    })

  }

  init_data2 = (_token) => {

    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string
    }

    this.init_data_option2(this.props.auth.token,obj).then((res,err) => {
        if(res.status === 200){
          this.setState({
            objectlist2: res.data,
            totalSize2: res.data.length
          })
          this.spinLoading2(false)
        }else{
          console.log("get data error !!")
        }
    })

  }

  init_data_option1 = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service1.getListOption(_token,_option).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }

  init_data_option2 = (_token,_option) => {
    return new Promise( ( resolve, reject ) => {
      service2.getListOption(_token,_option).then((res,err )=> {
        if (res.data != null){
          if(res.data.status === 200){
            resolve(res.data)
          }
        }
      })
    })
  }

  DataGridCallback = ( _row, _index ) => {}

  DataGridCallback2 = ( _row, _index ) => {}

  PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string
    }

    if(type === 'pagination' || type === 'sort' ){
      this.spinLoading1(true)
      this.init_data_option1(this.props.auth.token,obj).then((res,err) => {
          if(res.status === 200){
            this.setState({
              objectlist: res.data,
              totalSize: res.data.length
            })
            this.spinLoading1(false)
          }else{
            console.log("get data error !!")
          }
      })
    }

  }

  PaginationCallback2  = ( type , _limit, _offset , _filter_string , _page) => {

      let obj = {
        limit: _limit,
        offset: _offset,
        filter_string: _filter_string
      }

      if(type === 'pagination' ){
        this.spinLoading2(true)
        this.init_data_option2(this.props.auth.token,obj).then((res,err) => {
            if(res.status === 200){
              this.setState({
                objectlist2: res.data.results,
                totalSize2: res.data.count
              })
              this.spinLoading2(false)
            }else{
              console.log("get data error !!")
            }
        })
      }

  }

  render() {

    let { objectlist , totalSize , page , limit  , objectlist2 , totalSize2 , page2 , limit2  } = this.state
    // console.log("objectlist =>" ,objectlist)
    // console.log("objectlist2 =>" ,objectlist2)

    return (

          <div>
            <Row gutter={16}>
              <Col span={4}>
                <h2>Dashboard</h2>
              </Col>
              <Col span={8}>
                  <Clock className="live-clock" format={'DD/MM/YYYY HH:mm:ss'} ticking={true} timezone={'Asia/Bangkok'} />
              </Col>
            </Row>
            <br/>

           <HeaderDashboard />
<br/>
            <Row gutter={16}>
              <Col span={24}>
              <h3>ธุรกรรมที่ยังไม่ได้ส่งยอด</h3>
                {
                  (
                    objectlist2.length === 0
                    ? <div>ไม่พบข้อมูล</div>
                    :
                  
                <Spin spinning={this.state.loading2} delay={300} tip="Loading...">
                  <DataGrid callback={this.DataGridCallback2} totalSize={totalSize2} page={page2} limit={limit2} paginationCallback={this.PaginationCallback2} columns={columns2} dataList={objectlist2} />
                </Spin>
                  )
                }
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
              <h3>ธุรกรรมที่เกิดขึ้นวันนี้</h3>
                {
                  (
                    objectlist.length === 0
                    ? <div>ไม่พบข้อมูล</div>
                    :
                    null
                  )
                }
                <Spin spinning={this.state.loading1} delay={300} tip="Loading...">
                  <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
                </Spin>
              </Col>
            </Row>
            
          </div>

    );

  }
}

const mapStateToProps = state => {
  return { auth: state.auth}
}

export default connect( mapStateToProps )( Dashboard );
