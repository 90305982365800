import { createActions, createReducer, } from "reduxsauce"
import * as Immutable from "seamless-immutable"

const tag = '[SAGA_BUSSTOPTABLE_REDUX]'

const functionDefinitions = {
  fetchBusstopData: ['token'],
  fetchBusstopSuccess: ["fetchBusstopSuccess"],
  fetchBusstopFailure: [ "fetchBusstopFailure" ]
}

const { Types, Creators, } = createActions( functionDefinitions )

export const ActionType = Types;
export default Creators;

const initState = {
  isLoading: false,
  payLoad: null,
  error: null,
}

export const INITIAL_STATE = Immutable( initState )

export const fetchBusstopData = ( state, action ) => {
  return state.merge( { isLoading: true, payLoad: action.token, error: null, } );
}

export const fetchBusstopSuccess = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: action.fetchBusstopSuccess, error: null, } );
}

export const fetchBusstopFailure = ( state, action ) => {
  return state.merge( { isLoading: false, payLoad: null, error: action.fetchBusstopFailure, } );
};

export const reducer = createReducer( INITIAL_STATE, {
  [ Types.FETCH_BUSSTOP_DATA ]: fetchBusstopData,
  [ Types.FETCH_BUSSTOP_SUCCESS ]: fetchBusstopSuccess,
  [ Types.FETCH_BUSSTOP_FAILURE ]: fetchBusstopFailure,
} )
