const dataSchema = {
    required:[],
    dataStructure:{
        id: null,
        bus: null,
        fuel_date:null,
        mileage_previous: null,
        mileage_current: null,
        mileage: null,
        gas_volume: null,
        gas_money: null,
        gas_volume_vga: null,
        gas_mileage_vga: null,
        gas_full_unit_price: null,
        gas_discount_unit_price: null,
        gas_unit_price_different: null,
        gas_total_price: null,
        gas_total_paid: null,
        gas_total_discount: null,
        service_station: null
    },
    properties:{
        id: {
            key:"id",
            type: "integer",
            label: "ID",
        },
        bus: {
            key:"bus",
            type: "string",
            label: "bus id",
        },
        fuel_date: {
            key: "fuel_date",
            type: "datetime",
            label: "วันที่เติม"
        },
        mileage_previous: {
            key:"mileage_previous",
            type: "integer",
            label: "เลขไมล์ก่่อนหน้า"
        },
        mileage_current: {
            key:"mileage_current",
            type: "integer",
            label: "เลขไมล์ปัจจุบัน"
        },
        mileage: {
            key:"mileage",
            type: "integer",
            label: "ความต่างเลขไมล์"
        },
        gas_volume: {
            key:"gas_volume",
            type: "integer",
            label: "ปริมาณแก๊สที่เติม"
        },
        gas_money: {
            key:"gas_money",
            type: "integer",
            label: "ราคาแก๊สที่เติม"
        },
        gas_volume_vga: {
            key:"gas_volume_vga",
            type: "integer",
            label: "อัตราการสิ้นเปลือง"
        },
        gas_mileage_vga: {
            key:"gas_mileage_vga",
            type: "integer",
            label: "ระยะทาง"
        },
        gas_full_unit_price: {
            key:"gas_full_unit_price",
            type: "integer",
            label: "ราคาเต็ม"
        },
        gas_discount_unit_price: {
            key:"gas_discount_unit_price",
            type: "integer",
            label: "ส่วนลด"
        },
        gas_unit_price_different: {
            key:"gas_unit_price_difference",
            type: "integer",
            label: "ผลต่าง"
        },
        gas_total_price: {
            key:"gas_total_price",
            type: "integer",
            label: "รวมราคาเต็ม"
        },
        gas_total_paid: {
            key:"gas_total_paid",
            type: "integer",
            label: "รวมราคาจ่าย"
        },
        gas_total_discount: {
            key:"gas_total_discount",
            type: "integer",
            label: "รวมส่วนลด"
        },
        service_station: {
            key:"service_station",
            type: "string",
            label: "สถานีบริการ"
        }
    }
}
export const schema = dataSchema